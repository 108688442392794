import { useMemo, useState } from 'react'

const PAGE_STATE_CART = 'Cart.pageState.cart'
const PAGE_STATE_CONFIRM = 'Cart.pageState.confirm'
const PAGE_STATE_COMPLETE = 'Cart.pageState.complete'

export default function usePageState() {
  const [pageState, setPageState] = useState(PAGE_STATE_CART)

  const toCart = () => setPageState(PAGE_STATE_CART)
  const toConfirm = () => setPageState(PAGE_STATE_CONFIRM)
  const toComplete = () => setPageState(PAGE_STATE_COMPLETE)

  const isCart = useMemo(
    () => pageState === PAGE_STATE_CART,
    [pageState]
  )

  const isConfirm = useMemo(
    () => pageState === PAGE_STATE_CONFIRM,
    [pageState]
  )

  const isComplete = useMemo(
    () => pageState === PAGE_STATE_COMPLETE,
    [pageState]
  )

  const pageTitle = useMemo(() => {
    switch (pageState) {
      case PAGE_STATE_CONFIRM:
        return 'ご注文内容の確認'
      case PAGE_STATE_COMPLETE:
        return 'ご注文の完了'
      default:
        return 'ショッピングカート'
    }
  }, [pageState])

  return { toCart, toConfirm, toComplete, isCart, isConfirm, isComplete, pageTitle }
}
