import { useEffect, useState } from 'react'
import { exchangeReasons as exchangeReasonsApi } from '../../../api/rental'

export default function useExchangeReason() {
  const [reasons, setReasons] = useState([])
  const [reason, setReason] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await exchangeReasonsApi()

      setReasons(response.data)
    })()
  }, [])

  return { reasons, reason, setReason }
}
