import React from 'react'
import { Router } from '@reach/router'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/ja_JP'
import 'moment/locale/ja'
import store from './store'
import './styles/app.less'

import GuestRoute from './components/GuestRoute'
import PrivateRoute from './components/PrivateRoute'

import Layout from './components/Layout'
import Home from './pages/Home'
import Login from './pages/Login'
import Account from './pages/Account'
import ProductSearch from './pages/ProductSearch'
import ProductShow from './pages/ProductShow'
import Cart from './pages/Cart/'
import OrderSearch from './pages/OrderSearch'
import OrderShow from './pages/OrderShow/'
import ReturnShow from './pages/OrderShow/ReturnShow'
import ExchangeShow from './pages/OrderShow/ExchangeShow'
import PaymentSearch from './pages/PaymentSearch'
import PaymentShow from './pages/PaymentShow/'
import BillingSearch from './pages/BillingSearch'
import BillingShow from './pages/BillingShow/'
import BillingCreate from './pages/BillingCreate'
import ProceedsSearch from './pages/ProceedsSearch'
import CSVDownload from './pages/CSVDownload'
import CSVUpload from './pages/CSVUpload'
import Contact from './pages/Contact'
import NoMatch from './pages/NoMatch'
import AccountSearch from './pages/AccountSearch'
import AccountProductSearch from './pages/AccountProductSearch'
import AccountProductShow from './pages/AccountProductShow'
import AccountCreate from './pages/AccountCreate'
import RentalItemSearch from './pages/RentalItemSearch'
import PasswordChange from './pages/PasswordChange'
import { ROLE_ADMIN, ROLE_BUYER, ROLE_SELLER } from './constants'
import PaymentSummary from './pages/PaymentSummary'
import Guide from './pages/Guide'
import ForumSearch from './pages/ForumSearch'
import ForumShow from './pages/ForumShow'
import RentalStatusSearch from './pages/RentalStatusSearch/'
import RentalStatusShow from './pages/RentalStatusShow/'
import ReturnAccept from './pages/ReturnAccept'
import RentalTransfer from './pages/RentalTransfer'
import RentalExchange from './pages/RentalExchange'
import RentalCancel from './pages/RentalCancel'
import BatchAdmin from './pages/BatchAdmin'
import StockSearch from './pages/Stock/StockSearch'
import TransferOrderSearch from './pages/TransferOrderSearch'
import TransferOrderShow from './pages/TransferOrderShow/'
import UsageStatusSearch from './pages/UsageStatusSearch/'
import NextDeliveryChange from './pages/NextDeliveryChange'
import PasswordChangeRoute from './components/PasswordChangeRoute'

// Ant Design 用の設定値
const uiConfig = {
  renderEmpty: () => null,
  locale,
}

function App() {
  return (
    <ConfigProvider {...uiConfig}>
      <Provider store={store}>
        <Layout>
          <Router>
            <PrivateRoute path="/:companyCode" as={Home} />
            <PrivateRoute path="/:companyCode/account" as={Account} />
            <PrivateRoute path="/:companyCode/rental-item-search" as={RentalItemSearch} />
            <PrivateRoute path="/:companyCode/products" as={ProductSearch} roles={[ROLE_BUYER, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/products/:id" as={ProductShow} roles={[ROLE_BUYER, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/cart" as={Cart} roles={[ROLE_BUYER, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/orders" as={OrderSearch} />
            <PrivateRoute path="/:companyCode/orders/:id" as={OrderShow} />
            <PrivateRoute path="/:companyCode/orders/:id/return" as={ReturnShow} />
            <PrivateRoute path="/:companyCode/orders/:id/exchange" as={ExchangeShow} />
            <PrivateRoute path="/:companyCode/payments" as={PaymentSearch} roles={[ROLE_BUYER, ROLE_SELLER]}/>
            <PrivateRoute path="/:companyCode/payments/company/:companyId/:year/:month" as={PaymentShow} roles={[ROLE_BUYER, ROLE_SELLER]}/>
            <PrivateRoute path="/:companyCode/payments/store/:companyId/:storeId/:year/:month" as={PaymentShow} roles={[ROLE_BUYER, ROLE_SELLER]}/>
            <PrivateRoute path="/:companyCode/summary" as={PaymentSummary} roles={[ROLE_BUYER, ROLE_SELLER]} parentOnly />
            <PrivateRoute path="/:companyCode/billings" as={BillingSearch} roles={[ROLE_ADMIN, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/billings/company/:companyId/:year/:month" as={BillingShow} roles={[ROLE_ADMIN, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/billings/store/:companyId/:storeId/:year/:month" as={BillingShow} roles={[ROLE_ADMIN, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/proceeds" as={ProceedsSearch} roles={[ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/billings/create" as={BillingCreate} roles={[ROLE_ADMIN, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/csv-download" as={CSVDownload} roles={[ROLE_ADMIN]} />
            <PrivateRoute path="/:companyCode/csv-upload" as={CSVUpload} roles={[ROLE_ADMIN]} />
            <PrivateRoute path="/:companyCode/batch" as={BatchAdmin} roles={[ROLE_ADMIN]} />
            <PrivateRoute path="/:companyCode/contact" as={Contact} />
            <PrivateRoute path="/:companyCode/accounts" as={AccountSearch} />
            <PrivateRoute path="/:companyCode/accounts/:id/products" as={AccountProductSearch} />
            <PrivateRoute path="/:companyCode/accounts/:id/products/:productId" as={AccountProductShow} />
            <PrivateRoute path="/:companyCode/accounts/create" as={AccountCreate} roles={[ROLE_ADMIN]} />
            <PrivateRoute path="/:companyCode/guides" as={Guide} roles={[ROLE_ADMIN, ROLE_BUYER, ROLE_SELLER]} />
            <PrivateRoute path="/:companyCode/forums" as={ForumSearch} />
            <PrivateRoute path="/:companyCode/forums/:id" as={ForumShow} />
            <PrivateRoute path="/:companyCode/rental-status/:accountId" as={RentalStatusShow} />
            <PrivateRoute path="/:companyCode/rental-status" as={RentalStatusSearch} />
            <PrivateRoute path="/:companyCode/returns/:orderId/accept" as={ReturnAccept} roles={[ROLE_ADMIN]} />
            <PrivateRoute path="/:companyCode/usage-status" as={UsageStatusSearch} />
            <PrivateRoute path="/:companyCode/usage-status/change" as={NextDeliveryChange} />
            <PrivateRoute path="/:companyCode/stocks" as={StockSearch} />
            <PrivateRoute path="/:companyCode/accounts/:accountId/rental-products/:pNo/transfer" as={RentalTransfer} />
            <PrivateRoute path="/:companyCode/accounts/:accountId/rental-products/:pNo/exchange" as={RentalExchange} />
            <PrivateRoute path="/:companyCode/accounts/:accountId/rental-products/:pNo/cancel" as={RentalCancel} />
            <PrivateRoute path="/:companyCode/transfer-orders" as={TransferOrderSearch} />
            <PrivateRoute path="/:companyCode/orders/:id/transfer" as={TransferOrderShow} />
            <PasswordChangeRoute path="/password-change" as={PasswordChange} />
            <GuestRoute path="/:companyCode/login" as={Login} />
            <NoMatch default />
          </Router>
        </Layout>
      </Provider>
    </ConfigProvider>
  )
}

export default App
