import { useEffect, useState } from 'react'
import { products as returnAcceptProductsApi } from '../../../api/return-accept'

export default function useReturnTable(orderId) {
  const [products, setProducts] = useState([])
  const [counts, setCounts] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await returnAcceptProductsApi(orderId)

      setProducts(response.data)

      const returnCounts = {}
      response.data.forEach(product => {
        returnCounts[product.pNo] = { ok: 0, repair: 0, discard: 0 }
      })
      setCounts(returnCounts)
    })()
  }, [orderId])

  const updateCounts = (pNo, count) => setCounts({
    ...counts, [pNo]: { ...counts[pNo], ...count }
  })

  return { products, counts, updateCounts }
}
