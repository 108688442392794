import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
  Row,
  Col,
  Spin,
  Input,
  Table,
} from 'antd'
import Page from '../../components/Page'
import SearchResult from '../../components/SearchResult'
import { usageDays } from '../../api/rental'
import { RESPONSE_OK } from '../../constants'

const { Column } = Table

function RentalItemSearch() {
  const initialQuery = { barcode: '' }
  const [rentalItems, setRentalItems] = useState([])
  const [barcode, setBarCode] = useState('')
  const [query, setQuery] = useState(initialQuery)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await usageDays({ barcode: barcode })
      if (response.status === RESPONSE_OK) {
        const { rentalItems } = response.data
        setRentalItems(rentalItems)
      }
      setLoading(false)
    })()
  }, [query])

  const handleSearch = e => {
    e.preventDefault()
    setQuery({ barcode: barcode })
  }

  const formItemStyle = { marginBottom: 0, paddingBottom: 0 }

  return (
    <Page title="個別バーコード利用期間検索">
      <Form layout="vertical">
        <div className="mt-3 mb-3">
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={24}>
                <Form.Item label="個別バーコード" style={formItemStyle}>
                  <Input
                    value={barcode}
                    onChange={event => setBarCode(event.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="mt-3 text-center">
            <Button htmlType="submit" type="primary" onClick={handleSearch}>
              検索
            </Button>
          </div>
        </div>

        <div className="mt-3 mb-3">
          <Spin spinning={loading}>
            <SearchResult
              zeroMessage="検索条件に一致する商品が見つかりませんでした。"
              forceError={rentalItems.length < 1}
            >
              <div className="mb-4">
                <Table
                  bordered
                  pagination={false}
                  dataSource={rentalItems}
                  rowKey="barcode"
                >
                  <Column
                    key="barcode"
                    title="個別バーコード"
                    render={record => record.barcode}
                  />
                  <Column
                    key="pNo"
                    title="商品コード"
                    render={record => record.pNo}
                  />
                  <Column
                    key="title"
                    title="商品名"
                    render={record => record.productTitle}
                  />
                  <Column
                    key="usageDays"
                    title="利用期間累計"
                    render={record => `${record.usageDays}日`}
                  />
                </Table>
              </div>
            </SearchResult>
          </Spin>
        </div>
      </Form>
    </Page>
  )
}

export default RentalItemSearch
