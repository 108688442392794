import React, { useEffect, useMemo, useState } from 'react'
import { appNavigate } from '../components/AppLink'
import moment from 'moment'
import {
  Form,
  Button,
  DatePicker,
  Row,
  Col,
  Divider,
  Card,
  Icon,
  Select,
  Spin,
  Checkbox,
  Input,
} from 'antd'
import Page from '../components/Page'
import LinkButton from '../components/LinkButton'
import Pagination from '../components/Pagination'
import OrderTag from '../components/OrderTag'
import OrderType from '../components/OrderType'
import ReturnTags from '../components/ReturnTags'
import SearchResult from '../components/SearchResult'
import orderApi from '../api/order'
import { dateFormat, numFormat } from '../formatter'
import scrollTop from '../scroll-top'
import queryString from "query-string"
import { 
  DATE_FORMAT,
  RESPONSE_OK,
  ORDER_TYPE_SHOPPING,
  ORDER_TYPE_RENTAL,
  ORDER_TYPE_CHANGE,
  ORDER_TYPE_CANCEL,
  ORDER_TYPE_TRANSFER,
  ORDER_TYPE_RETURN,
  ORDER_STATUS_CANCEL,
  USAGE_STATUS_TYPE1
} from '../constants'
import { ROLE_SELLER } from '../constants'
import useCompanyStoreSelect from '../hooks/company-store-select'
import {
  useCurrentUser,
  useIsAdmin,
  useIsChildStore,
  useShowPrice,
  useIsStatusUse,
  useStatusUseType,
  useShowShipmentFee
} from '../hooks/auth'
import {useOrderStatusSelect, useReturnStatusSelect} from '../hooks/order-return-status-select'

const { Option } = Select

const formItemStyle = {
  marginBottom: 0,
  paddingBottom: 0,
}

const emptyQuery = {
  from: '',
  to: '',
  storeId: null,
  companyId: null,
  page: 1
}

function OrderSearch(props) {
  const isStatusUse = useIsStatusUse()
  const isChildStore = useIsChildStore()
  const isAdmin = useIsAdmin()

  const searchParams = queryString.parse(props.location.search)

  const initialQuery = {
    companyId: searchParams.companyId * 1 || null,
    storeId: searchParams.storeId * 1 || null,
    from: searchParams.from || '',
    to: searchParams.to || '',
    page: searchParams.page * 1 || 1,
    orderId: searchParams.orderId || '',
    orderStatus: searchParams.orderStatus * 1 || null,
    returnStatus: searchParams.returnStatus * 1 || null,
    staffNo: searchParams.staffNo || '',
    staffName: searchParams.staffName || ''
  }

  const isReturnToPage = searchParams.storeId != null

  if (isAdmin) {
    initialQuery['types'] = []
  }

  const initialDateRange = searchParams.from ? 
    [moment(searchParams.from), moment(searchParams.to)]
    : []

  const [query, setQuery] = useState(initialQuery)
  const [orders, setOrders] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [dateRange, setDateRange] = useState(initialDateRange)
  const [types, setTypes] = useState([])
  const [isReturnWaiting, setIsReturnWaiting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [orderId, setOrderId] = useState('')
  const [orderStatus, setOrderStatus] = useState(null)
  const [returnStatus, setReturnStatus] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedStaffNo, setSelectedStaffNo] = useState(null)
  const [selectedStaffName, setSelectedStaffName] = useState(null)
  const {
    companies, stores, companyId, setCompanyId, storeId, setStoreId
  } = useCompanyStoreSelect({ isChildStore, initialCompanyId: initialQuery.companyId, initialStoreId: initialQuery.storeId, isReturnToPage })

  const statuses = useOrderStatusSelect()

  const showPrice = useShowPrice()

  useEffect(() => {
    (async () => {
      scrollTop()
      setLoading(true)
      const response = await orderApi.search(query)
      if (response.status === RESPONSE_OK) {
        const { orders: items, ...rest } = response.data
        setOrders(items)
        setPageInfo(rest)
      }
      setLoading(false)
    })()
  }, [query])

  const dateRangeQuery = useMemo(() => {
    if (dateRange.length === 0) {
      return { 
        from: '', to: ''
      }
    }
    return {
      from: dateRange[0].format('YYYY-MM-DD') ,
      to: dateRange[1].format('YYYY-MM-DD') ,
    }
  }, [dateRange])

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    const params = { ...dateRangeQuery, page: 1, companyId, storeId, orderId, orderStatus, returnStatus, staffNo: selectedStaffNo, staffName: selectedStaffName }
    
    if (isAdmin) {
      params.types = types
      params.isReturnWaiting = isReturnWaiting
    }

    setQuery(params)
    appNavigate("/orders?" + queryString.stringify(params, { skipEmptyString: true }))
  }

  const downloadCsv = async () => {
    setLoading(true)
    await orderApi.csv(query)
    setLoading(false)
  }

  const handleIsReturnWaitingChange = e => {
    setIsReturnWaiting(e.target.checked)

    if (e.target.checked) {
      setTypes(types.filter(t => (
        t === ORDER_TYPE_CHANGE || t === ORDER_TYPE_CANCEL
      )))
    }
  }

  const handleChangeStatus = e => {
    setSelectedStatus(e)
    if(e !== undefined) {
      if (e.includes("o")) {
        const status = e.replace("o", "")
        setOrderStatus(status)
        setReturnStatus(null)
      } else {
        const status = e.replace("r", "")
        setReturnStatus(status)
        setOrderStatus(null)
      }
    } else {
      setOrderStatus(null)
      setReturnStatus(null)
    }
  }

  return (
    <Page title="注文履歴">
      <div className="mb-3">
        <Form layout="vertical" onSubmit={handleSearch}>
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={8}>
                <Form.Item label="期間" style={formItemStyle}>
                  <DatePicker.RangePicker
                    format={DATE_FORMAT}
                    value={dateRange}
                    onChange={dates => setDateRange(dates)}
                  />
                </Form.Item>
              </Col>
              {!isChildStore && (
                <>
                  <Col span={8}>
                    <Form.Item label="企業名" style={formItemStyle}>
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        value={companyId}
                        onChange={val => setCompanyId(val)}
                      >
                        {companies.map(item => (
                          <Option key={item.companyId} value={item.companyId}>
                            {item.companyName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="店舗名" style={formItemStyle}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        value={storeId}
                        onChange={val => setStoreId(val)}
                      >
                        {stores.map(item => (
                          <Option key={item.storeId} value={item.storeId}>
                            {item.storeName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <div className="mt-3">
              <Row gutter={24} type="flex" align="bottom">
                <Col span={isStatusUse ? 6 : 16 }>
                  <Form.Item label="注文番号" style={formItemStyle}>
                    <Input
                      value={orderId}
                      onChange={event => setOrderId(event.target.value)}
                    />
                  </Form.Item>
                </Col>

                <Col span={isStatusUse ? 6 : 8 }>
                  <Form.Item label="注文ステータス" style={formItemStyle}>
                    <Select
                      allowClear
                      value={selectedStatus}
                      onChange={val => handleChangeStatus(val)}
                    >
                      {statuses.map(item => (
                        <Option key={item.key} value={item.statusCode}>
                          {item.statusName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                {isStatusUse &&
                  <Col span={6}>
                    <Form.Item label="社員番号" style={formItemStyle}>
                      <Input
                        value={selectedStaffNo}
                        onChange={event => setSelectedStaffNo(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                }
                {isStatusUse &&
                  <Col span={6}>
                    <Form.Item label="社員名" style={formItemStyle}>
                      <Input
                        value={selectedStaffName}
                        onChange={event => setSelectedStaffName(event.target.value)}
                      />
                    </Form.Item>
                  </Col>
                }
              </Row>
            </div>
            {isAdmin && (
              <div className="mt-3">
                <Row gutter={24} type="flex" align="bottom">
                  <Col span={16}>
                    <Form.Item label="種別" style={formItemStyle}>
                      <Select
                        mode="multiple"
                        value={types}
                        onChange={val => setTypes(val)}
                      >
                        <Option
                          value={ORDER_TYPE_SHOPPING}
                          disabled={isReturnWaiting}
                        >
                          注文
                        </Option>
                        <Option
                          value={ORDER_TYPE_RENTAL}
                          disabled={isReturnWaiting}
                        >
                          レンタル
                        </Option>
                        <Option value={ORDER_TYPE_CHANGE}>交換</Option>
                        <Option value={ORDER_TYPE_CANCEL}>解約</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <div className="mb-1">
                      <Checkbox
                        checked={isReturnWaiting}
                        onChange={handleIsReturnWaitingChange}
                      >
                        返品待ちのみ
                      </Checkbox>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <div className="text-center mt-3">
            <Button type="primary" htmlType="submit" disabled={loading}>
              検索する
            </Button>
            <Button htmlType="button" className="ml-1" disabled={loading} onClick={downloadCsv}>
              CSVファイル出力
            </Button>
          </div>
        </Form>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
          disabled={loading}
        />
      )}

      <Spin spinning={loading}>
        <div className="mt-3 mb-3">
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="注文履歴が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            {orders.map((order, index) => (
              <div key={order.orderId} className={index === 0 ? '' : 'mt-3'}>
                <OrderCell item={order} query={query} showPrice={showPrice}/>
              </div>
            ))}
          </SearchResult>
        </div>
      </Spin>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </Page>
  )
}

function OrderCell({ item, query, showPrice }) {
  const user = useCurrentUser()
  const isStatusUse = useIsStatusUse()
  const isCalcShipmentFee = useShowShipmentFee()
  const { isStatusUseType } = useStatusUseType()

  function getOrdererInfo(typeCode) {
    switch (typeCode) {
      case ORDER_TYPE_CHANGE:
        return ['交換申込日', '申込者', '/exchange']
      case ORDER_TYPE_CANCEL:
        return ['解約日', '解約者', '/return']
      case ORDER_TYPE_TRANSFER:
        return ['申請日', '申請者', '/return']
      case ORDER_TYPE_RETURN:
          return ['返品登録日', '返品者', '']
      default:
        return ['注文日', '注文者', '']
    }
  }

  const [dateTag, orderer, showUrl] = getOrdererInfo(item.typeCode)

  const renderExtra = () => (
    <>
      {item.typeCode === ORDER_TYPE_RETURN ?
        (<span>注文番号：{item.orderId} （元注文番号：{item.restockSourceOrderId}）</span>)
      : (<span>注文番号：{item.orderId}</span>)
      }
      <Divider type="vertical" style={{ backgroundColor: `#d9d9d9` }} />
      <LinkButton to={`/orders/${item.orderId}${showUrl}`} state={query} type="primary" small ghost app>
        <span>詳細を見る</span>
        <Icon type="right" />
      </LinkButton>
    </>
  )

  const hasCompanyName = (
    user.isParentStore ||
    user.isCompanyAdmin ||
    user.roleId === ROLE_SELLER
  )

  const hasReturnTags = (
    (item.typeCode === ORDER_TYPE_CHANGE && item.statusCode !== ORDER_STATUS_CANCEL) ||
    (item.typeCode === ORDER_TYPE_CANCEL && item.statusCode !== ORDER_STATUS_CANCEL)
  )

  const hasStaffInfos = ((item.staffNo && !!item.staffNo.length) && (item.staffName && !!item.staffName.length))

  const totalAmountSign = (item) => item.typeCode === ORDER_TYPE_RETURN ? '- ' : ''

  if (item.rerental) {
    item.typeName = "再レンタル（解約申込解除）"
  }

  return (
    <Card
      title={<OrderType type={item.typeCode} name={item.typeName} />}
      extra={renderExtra()}
      headStyle={{ backgroundColor: `#fafafa` }}
    >
      <Row gutter={24}>
        <Col span={hasReturnTags ? 24 : 16}>
          <div className="mb-2">
            <OrderTag status={item.statusCode} type={item.typeCode}>
              {item.statusName}
            </OrderTag>
            <Divider type="vertical" />
            {item.orderedAt ? (
              <span>{dateTag}：{dateFormat(item.orderedAt)}</span>
            ) : item.returnedAt ? (
                <span>{dateTag}：{dateFormat(item.returnedAt)}</span>
              ) : (
                <span>注文予約日：{dateFormat(item.reservedAt)}</span>
              )
            }
            <Divider type="vertical" />
            <span>{orderer}：{hasCompanyName && item.companyName + " "}{item.storeName}</span>
            {hasReturnTags && (
              <ReturnTags
                status={item.returnCode}
                orderType={item.typeCode} 
                checkedDate={item.checkedAt}
              />
            )}
          </div>
          {isStatusUse && hasStaffInfos &&
            <div className="mb-2">
              <span>社員番号：{item.staffNo}</span>
              <Divider type="vertical" />
              <span>社員名：{item.staffName}</span>
              <Divider type="vertical" />
              <span>所属支店：{item.staffStoreName}</span>
            </div>
          }
          {isStatusUseType(USAGE_STATUS_TYPE1) && hasStaffInfos && item.isPersonalOrder &&
            <div className="mb-2">
              <span>注文分類：個人注文</span>
            </div>
          }
          <ul className="plain-list">
            {item.products.map((product, index) => (
              <li key={`product-${index}`}>
                <span>{product.title}</span>
                <Divider type="vertical" />
                <span>{product.size}</span>
                <Divider type="vertical" />
                <span className="text-sm">
                  数量：{numFormat(product.orderCount)}
                </span>
              </li>
            ))}
          </ul>
        </Col>
        {!hasReturnTags && (
          <Col span={8}>
          {!showPrice && (
            <div className="panel">
              <Row type="flex" justify="space-between" align="middle">
                <Col>合計金額</Col>
                <Col>
                  <div className="text-bold">
                    {totalAmountSign(item)}
                    {numFormat(isCalcShipmentFee ? item.totalAmount: item.productTotalAmount )} 円
                  </div>
                </Col>
              </Row>
            </div>
          )}
          </Col>
        )}
      </Row>
    </Card>
  )
}

export default OrderSearch
