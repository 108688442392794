// 認証系APIではカスタムインスタンスを使用しない
import axios from 'axios'
// ログアウトはカスタムインスタンスを使用する
import httpClient from '../http'

export default {
  login(data) {
    return axios.post(`/api/login`, data)
  },

  logout() {
    return httpClient.post(`/api/logout`)
  },

  account() {
    return axios.get(`/api/my-account`)
  }
}
