import React from 'react'

function Tag({ children, type }) {
  const typeClass = getClass(type)
  return <span className={`tag ${typeClass}`}>{children}</span>
}

function getClass(type) {
  return `is-${type || 'default'}`
}

export default Tag
