import { createSlice } from '@reduxjs/toolkit';
import authApi from '../api/auth';
import { useSelector } from 'react-redux';

const STATUS_LOADING = 'loading'
const STATUS_ERROR = 'error'
const STATUS_LOCK = 'lock'

const initialState = {
  user: null,
  loginStatus: '',
  companyCode: '',
}

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload
    },
    setLoginStatus(state, action) {
      state.loginStatus = action.payload
    },
    setCompanyCode(state, action) {
      state.companyCode = action.payload
    }
  }
})

export default slice.reducer

const { setUser, setLoginStatus } = slice.actions
export const { setCompanyCode } = slice.actions

// =====================================
// Async actions
// =====================================

export function login(data) {
  return async dispatch => {
    dispatch(setLoginStatus(STATUS_LOADING))

    try {
      await authApi.login(data)
      const { data: user } = await authApi.account()

      dispatch(setUser(user))
      dispatch(setLoginStatus(''))
    } catch (e) {
      if (e.response.status == 423){
        dispatch(setLoginStatus(STATUS_LOCK))  
      } else {
        dispatch(setLoginStatus(STATUS_ERROR))
      }
    }
  }
}

export function getCurrentUser() {
  return async dispatch => {
    try {
      const { data: user } = await authApi.account()
      dispatch(setUser(user))
    } catch (e) {}
  }
}

export function logout() {
  return async dispatch => {
    await authApi.logout()
    dispatch(setUser(null))
  }
}

// =====================================
// Selector hooks
// =====================================

export const useLoginLoading = () => useSelector(state => {
  return state.auth.loginStatus === STATUS_LOADING
})

export const useLoginError = () => useSelector(state => {
  return state.auth.loginStatus === STATUS_ERROR
})

export const useLoginLock = () => useSelector(state => {
  return state.auth.loginStatus === STATUS_LOCK
})