import React, { useEffect, useState } from 'react'
import { appNavigate } from '../components/AppLink'
import {
  Form,
  Button,
  Row,
  Col,
  Divider,
  Card,
  Icon,
  Select,
  Spin,
} from 'antd'
import Page from '../components/Page'
import LinkButton from '../components/LinkButton'
import Pagination from '../components/Pagination'
import OrderType from '../components/OrderType'
import SearchResult from '../components/SearchResult'
import Tag from '../components/Tag'

import transferOrder from '../api/transfer-order'
import { dateFormat, numFormat } from '../formatter'
import scrollTop from '../scroll-top'
import queryString from "query-string"
import { 
  RESPONSE_OK,
} from '../constants'
import { ROLE_SELLER } from '../constants'
import useCompanyStoreSelect from '../hooks/company-store-select'
import { useCurrentUser, useIsChildStore } from '../hooks/auth'

const { Option } = Select

const formItemStyle = {
  marginBottom: 0,
  paddingBottom: 0,
}

function TransferOrderSearch(props) {

  const isChildStore = useIsChildStore()

  const searchParams = queryString.parse(props.location.search)

  const initialQuery = {
    companyId: searchParams.companyId * 1 || null,
    storeId: searchParams.storeId * 1 || null,
    orderStatus: searchParams.orderStatus * 1 || null,
    page: searchParams.page * 1 || 1,
  }

  const isReturnToPage = searchParams.storeId != null

  const [query, setQuery] = useState(initialQuery)
  const [orders, setOrders] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [orderStatus, setOrderStatus] = useState(null)

  const {
    companies, stores, companyId, setCompanyId, storeId, setStoreId
  } = useCompanyStoreSelect({ isChildStore, initialCompanyId: initialQuery.companyId, initialStoreId: initialQuery.storeId, isReturnToPage })

  useEffect(() => {
    (async () => {
      scrollTop()
      setLoading(true)
      const response = await transferOrder.search(query)
      if (response.status === RESPONSE_OK) {
        const result = response.data
        setOrders(result)
        setPageInfo(result[0])
      }
      setLoading(false)
    })()
  }, [query])

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    const params = { page: 1, companyId, storeId, orderStatus }

    setQuery(params)
    appNavigate("/transfer-orders?" + queryString.stringify(params, { skipEmptyString: true }))
  }

  return (
    <Page title="移動申請履歴">
      <div className="mb-3">
        <Form layout="vertical" onSubmit={handleSearch}>
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              {!isChildStore && (
                <>
                  <Col span={8}>
                    <Form.Item label="企業名" style={formItemStyle}>
                      <Select
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        value={companyId}
                        onChange={val => setCompanyId(val)}
                      >
                        {companies.map(item => (
                          <Option key={item.companyId} value={item.companyId}>
                            {item.companyName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="店舗名" style={formItemStyle}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        allowClear
                        value={storeId}
                        onChange={val => setStoreId(val)}
                      >
                        {stores.map(item => (
                          <Option key={item.storeId} value={item.storeId}>
                            {item.storeName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={8}>
                <Form.Item label="申請ステータス" style={formItemStyle}>
                  <Select
                    allowClear
                    value={orderStatus}
                    onChange={val => setOrderStatus(val)}
                  >
                    <Option value={1}>
                      承認待ち
                    </Option>
                    <Option value={2}>
                      申請完了
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="text-center mt-3">
            <Button type="primary" htmlType="submit">
              検索する
            </Button>
          </div>
        </Form>
      </div>
      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}

      <Spin spinning={loading}>
        <div className="mt-3 mb-3">
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="注文履歴が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            {orders.map((order, index) => (
              <div key={order.orderId} className={index === 0 ? '' : 'mt-3'}>
                <OrderCell item={order} query={query}/>
              </div>
            ))}
          </SearchResult>
        </div>
      </Spin>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </Page>
  )
}

function OrderCell({ item, query }) {
  const user = useCurrentUser()

  const renderExtra = () => (
    <>
      <span>申請番号：{item.id}</span>
      <Divider type="vertical" style={{ backgroundColor: `#d9d9d9` }} />
      <LinkButton to={`/orders/${item.id}/transfer`} state={query} type="primary" small ghost app>
        <span>詳細を見る</span>
        <Icon type="right" />
      </LinkButton>
    </>
  )

  const hasCompanyName = (
    user.isParentStore ||
    user.isCompanyAdmin ||
    user.roleId === ROLE_SELLER
  )

  return (
    <Card
      title={<OrderType type={5} name="店舗移動" />}
      extra={renderExtra()}
      headStyle={{ backgroundColor: `#fafafa` }}
    >
      <Row gutter={[24, 12]} >
        <Col span={24}>
          <Tag type={item.statusCode === 1 ? "danger" : "primary"}>
            {item.statusCode === 1 ? "承認待ち" : "申請完了"}
          </Tag>
          <Divider type="vertical" />
            <span>申請日：{dateFormat(item.createdAt)}</span>
          <Divider type="vertical" />
          <span>申請者：{hasCompanyName && item.companyName + " "}{item.storeName}</span>
        </Col>
        <Col span={11} className="text-left">移動元店舗：{item.transferFromName}</Col>
        <Col span={2} className="text-center">
          <Icon type="arrow-right"></Icon>
        </Col>
        <Col span={11} className="text-left">移動先店舗：{item.transferToName}</Col>
        <Col span={24}>
          <div>
            <span>{item.title}</span>
            <Divider type="vertical" />
            <span>{item.size}</span>
            <Divider type="vertical" />
            <span className="text-sm">
              移動数：{numFormat(item.orderCount)}
            </span>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default TransferOrderSearch
