import React, { useEffect, useMemo, useState } from 'react'
import {
  Form,
  Button,
  Row,
  Col,
  List,
  Icon,
  Select,
  DatePicker,
  Spin,
  Divider
} from 'antd'
import moment from 'moment'
import Page from '../components/Page'
import LinkButton from '../components/LinkButton'
import Pagination from '../components/Pagination'
import SearchResult from '../components/SearchResult'
import { dateFormat, numFormat } from '../formatter'
import billingApi from '../api/billing'
import { MONTH_FORMAT, RESPONSE_OK, BILLING_SUMMARY_TYPE_COMPANY, BILLING_SUMMARY_TYPE_STORE } from '../constants'
import { useBillingSummaryType } from '../hooks/auth'

const { MonthPicker } = DatePicker
const { Option } = Select

const inputStyle = { width: '100%' }

function BillingSearch() {
  const [billings, setBillings] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [term, setTerm] = useState(moment())
  const [companies, setCompanies] = useState([])
  const [stores, setStores] = useState([])
  const [companyId, setCompanyId] = useState(null)
  const [storeId, setStoreId] = useState(null)
  const termText = useMemo(() => term ? term.format('YYYY-MM') : '', [term])
  const year = useMemo(() => termText.split('-')[0], [termText])
  const month = useMemo(() => termText.split('-')[1], [termText])
  const [query, setQuery] = useState({ term: termText, companyId, storeId, page: 1 })
  const [loading, setLoading] = useState(false)
  const { isBillingSummaryTypeWith } = useBillingSummaryType()

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await billingApi.destinations()
      if (response.status === RESPONSE_OK) {
        const { companyList, storeList } = response.data
        setCompanies(companyList)
        setStores(storeList)
      }
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      // 年月が指定されないと検索できない
      if (query.term === '') return () => {}
      setLoading(true)
      const response = await billingApi.index(query)
      if (response.status === RESPONSE_OK) {
        const { bills, ...rest } = response.data
        setBillings(bills)
        setPageInfo(rest)
      }
      setLoading(false)
    })()
  }, [query])

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    setQuery(Object.assign({}, query, {
      term: termText,
      companyId,
      storeId,
      page: 1,
    }))
  }

  const downloadCsv = async () => {
    setLoading(true)
    await billingApi.csv({ term: termText, companyId, storeId })
    setLoading(false)
  }

  return (
    <Page title="請求情報一覧">
      <Row type="flex" justify="end">
        <Col className="mb-2">
          <LinkButton to="/billings/create" type="default" app>
            <Icon type="plus" /> 請求データの作成
          </LinkButton>
        </Col>
      </Row>

      <div className="mb-3">
        <Form
          layout="vertical"
          colon={false}
          labelAlign="left"
          onSubmit={handleSearch}
        >
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={6}>
                <Form.Item label="対象月">
                  <MonthPicker
                    style={inputStyle}
                    format={MONTH_FORMAT}
                    placeholder="月を選択"
                    value={term}
                    onChange={term => setTerm(term)}
                  />
                </Form.Item>
              </Col>
              <Col span={isBillingSummaryTypeWith(BILLING_SUMMARY_TYPE_COMPANY) ? 18 : 9}>
                <Form.Item label="企業名">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    style={inputStyle}
                    value={companyId}
                    onChange={val => setCompanyId(val)}
                  >
                    {companies.map(item => (
                      <Option key={item.companyId} value={item.companyId}>
                        {item.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {isBillingSummaryTypeWith(BILLING_SUMMARY_TYPE_STORE) &&
                <Col span={isBillingSummaryTypeWith(BILLING_SUMMARY_TYPE_COMPANY) ? 0 : 9}>
                  <Form.Item label="店舗名">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      allowClear
                      style={inputStyle}
                      value={storeId}
                      onChange={val => setStoreId(val)}
                    >
                      {stores.map(item => (
                        <Option key={item.storeId} value={item.storeId}>
                          {item.storeName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              }
            </Row>
          </div>
          <div className="mt-3 text-center">
            <Button htmlType="submit" type="primary" disabled={!term || loading}>
              検索
            </Button>
            <Button htmlType="button" className="ml-1" onClick={downloadCsv} disabled={loading}>
              CSVファイル出力
            </Button>
          </div>
        </Form>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="請求情報が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            <List
              itemLayout="horizontal"
              dataSource={billings}
              renderItem={item => renderItem(item, year, month, isBillingSummaryTypeWith)}
            />
          </SearchResult>
        </Spin>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </Page>
  )
}

function renderItem(item, year, month, isBillingSummaryTypeWith) {
  return (
    <List.Item
      actions={[
        <span className="text-header">
          {numFormat(item.totalAmount + item.taxAmount)} 円
        </span>,
        <LinkButton
          to={isBillingSummaryTypeWith(BILLING_SUMMARY_TYPE_COMPANY) ?
            `/billings/company/${item.companyId}/${year}/${month}`:
            `/billings/store/${item.companyId}/${item.storeId}/${year}/${month}`
          }
          type="primary" small ghost app
        >
          <span>詳細を見る</span>
          <Icon type="right" />
        </LinkButton>,
      ]}
    >
      <List.Item.Meta
        title={
          <div className="mt-1">
            {"企業名： " + item.companyName}
            {item.storeName &&
              <span>
                 <Divider type="vertical" />
                 {"店舗名： " + item.storeName}
              </span>
            }
          </div>
        }
        description={<span>確定日：{dateFormat(item.billingConfirmedAt)}</span>}
      >
      </List.Item.Meta>
    </List.Item>
  )
}

export default BillingSearch
