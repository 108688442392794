import React from 'react'
import { Layout as AntLayout, Spin } from 'antd'
import { useResponseErrorStatus } from '../store/error'
import NotFound from './NotFound'
import SystemError from './SystemError'
import { RESPONSE_NOT_FOUND } from '../constants'

const headerStyles = {
  background: '#fff',
  padding: '0 1.5rem',
}

const contentStyles = {
  margin: '1.5rem 1rem 0',
}

function Page({ title, children, loading = false, mainStyle = {} }) {
  const errorStatus = useResponseErrorStatus()

  return (
    <>
      {/* Header */}
      {!errorStatus && title && (
        <AntLayout.Header style={headerStyles}>
          <h1 className="text-bold text-md">{title}</h1>
        </AntLayout.Header>
      )}

      {/* Main content */}
      <AntLayout.Content style={{ ...contentStyles, ...mainStyle }}>
        <div className="container">
          {loading ? (
            <div className="page-loading" children={<Spin />} />
          ) : (
            <div className="main">
              {errorStatus ? renderError(errorStatus) : children}
            </div>
          )}
        </div>
      </AntLayout.Content>
    </>
  )
}

function renderError(errorStatus) {
  if (errorStatus === RESPONSE_NOT_FOUND) {
    return <NotFound />
  } else {
    return <SystemError />
  }
}

export default Page
