import React, { useEffect, useMemo, useState } from 'react'
import {
  Form,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
} from 'antd'
import moment from 'moment'
import Page from '../components/Page'
import billingApi from '../api/billing'
import { MONTH_FORMAT, RESPONSE_OK } from '../constants'
import proceedsApi from '../api/proceeds'

const { MonthPicker } = DatePicker
const { Option } = Select

const inputStyle = { width: '100%' }

function ProceedsSearch() {
  const [term, setTerm] = useState(moment())
  const [companies, setCompanies] = useState([])
  const [companyId, setCompanyId] = useState(null)
  const termText = useMemo(() => term ? term.format('YYYY-MM') : '', [term])

  useEffect(() => {
    (async () => {
      const response = await billingApi.destinations()
      if (response.status === RESPONSE_OK) {
        const { companyList, storeList } = response.data
        setCompanies(companyList)
      }
    })()
  }, [])

  const downloadCsv = async () => {
    await proceedsApi.csv({ term: termText, companyId })
  }

  return (
    <Page title="販売情報">
      <div className="mb-3">
        <Form
          layout="vertical"
          colon={false}
          labelAlign="left"
        >
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={6}>
                <Form.Item label="対象月">
                  <MonthPicker
                    style={inputStyle}
                    format={MONTH_FORMAT}
                    placeholder="月を選択"
                    value={term}
                    onChange={term => setTerm(term)}
                  />
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label="企業名">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    style={inputStyle}
                    value={companyId}
                    onChange={val => setCompanyId(val)}
                  >
                    {companies.map(item => (
                      <Option key={item.companyId} value={item.companyId}>
                        {item.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div className="mt-1">
              <p>金額の確定は、選択された対象月の末日締め後に確定となります。</p>
              <p>確定前にCSVファイル出力されたデータは、月初から出力前日までの出荷完了分のデータです。</p>
            </div>
          </div>
          <div className="mt-3 text-center">
            <Button htmlType="button" className="ml-1" onClick={downloadCsv}>
              CSVファイル出力
            </Button>
          </div>
        </Form>
      </div>
    </Page>
  )
}

export default ProceedsSearch
