import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import {
  Form,
  Button,
  Row,
  Col,
  DatePicker,
  Spin,
  Input,
  Table
} from 'antd'
import Page from '../../components/Page'
import Pagination from '../../components/Pagination'
import SearchResult from '../../components/SearchResult'
import stockApi from '../../api/stock'
import { STOCK_PAGE_ITEMS, RESPONSE_OK, MONTH_FORMAT } from '../../constants'
import { notifySuccess, notifyError } from '../../notify'
import StockChangeModal from './StockChangeModal'
import '../../styles/stock.less'
import { useIsAdmin } from '../../hooks/auth'

const { MonthPicker } = DatePicker

const inputStyle = { width: '100%' }

const { Column } = Table


function StockSearch() {
  const isAdmin = useIsAdmin()
  const initialQuery = Object.assign(
    { title: null, pNo: null, term: moment().format('YYYY-MM')},
  )
  const [stocks, setStocks] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [title, setTitle] = useState(null)
  const [pNo, setPNo] = useState(null)
  const [query, setQuery] = useState(initialQuery)
  const [loading, setLoading] = useState(false)
  const [term, setTerm] = useState(moment())
  const termText = useMemo(() => term ? term.format('YYYY-MM') : '', [term])
  const [isThisMonth, setIsThisMonth] = useState(true)
  const [selectedStock, setSelectedStock] = useState(null)
  const [changeStockFlg, setChangeStockFlg] = useState(false)

  useEffect(() => {
    (async () => {
      // 年月が指定されないと検索できない
      if (query.term === '') return () => {}
      setLoading(true)
      const response = await stockApi.index(query)
      if (response.status === RESPONSE_OK) {
        const now = moment();
        const { stocks: stocks, ...rest } = response.data
        setStocks(stocks)
        setPageInfo(rest)
        setIsThisMonth(now.isSame(term, 'month'))
      }
      setLoading(false)
    })()
  }, [query, changeStockFlg])

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    const reqTitle = title !== "" ? title : null
    const reqPNo = pNo !== "" ? pNo : null
    const reqTerm = term !== "" ? termText : null
    setQuery(Object.assign({}, query, { title: reqTitle, pNo: reqPNo, term: reqTerm, page: 1 }))
  }

  // CSVファイル出力
  const downloadCsv = async () => {
    await stockApi.csv(query, termText)
  }

  const formItemStyle = { marginBottom: 0, paddingBottom: 0 }

  return (
    <Page title="在庫一覧">
      <div className="mb-3">
        <Form
          layout="vertical"
          colon={false}
          labelAlign="left"
          onSubmit={handleSearch}
        >
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={9}>
                <Form.Item label="商品名" style={formItemStyle}>
                  <Input
                    value={title}
                    onChange={event => setTitle(event.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item label="品番" style={formItemStyle}>
                  <Input
                    value={pNo}
                    onChange={event => setPNo(event.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="対象月" style={formItemStyle}>
                  <MonthPicker
                    style={inputStyle}
                    format={MONTH_FORMAT}
                    placeholder="月を選択"
                    value={term}
                    onChange={term => setTerm(term)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="mt-3 text-center">
            <Button htmlType="submit" type="primary">
              検索
            </Button>
            <Button htmlType="button" className="ml-1" onClick={downloadCsv}>
              CSVファイル出力
            </Button>
          </div>
        </Form>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
          pageSize={STOCK_PAGE_ITEMS}
        />
      )}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="在庫が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            <div className="mb-4">
              <Table
                bordered
                pagination={false}
                dataSource={stocks}
                rowKey="pNo"
              >
                <Column
                  key="pNo"
                  title="品番"
                  render={(record) => (
                    <div className="text-paragraph">
                      <div>
                        {record.pno}
                      </div>
                    </div>
                  )}
                />
                <Column
                  key="title"
                  title="商品名"
                  render={(record) => (
                    <div className="text-paragraph">
                      <div>
                        {record.title}
                      </div>
                    </div>
                  )}
                />
                <Column
                  key="size"
                  title="サイズ"
                  className="small-cell"
                  render={(record) => (
                    <div className="text-paragraph">
                      <div>
                        {record.size}
                      </div>
                    </div>
                  )}
                />
                <Column
                  key="stockCount"
                  title="注文可能数量"
                  dataIndex="stockCount"
                  className="small-cell"
                />
                <Column
                  key="allocationCount"
                  title="発送待ち数量"
                  dataIndex="allocationCount"
                  className="small-cell"
                />
                <Column
                  key="allStockCount"
                  title="倉庫在庫"
                  {...(isAdmin && isThisMonth ? {
                    render: (record) => (
                      <div className="text-paragraph">
                        <Row type="flex" justify="space-between" align="middle">
                          <Col>{record.allStockCount}</Col>
                          <Col>
                            <Button
                              className='stock-update-btn'
                              ghost
                              type='primary'
                              style={{marginRight: "100%"}}
                              size='small'
                              onClick={() => setSelectedStock(record)}
                              >更新
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )
                  }: {
                    dataIndex: "allStockCount",
                    className: "small-cell"
                  })}
                />
                <Column
                  key="orderCount"
                  title="販売数"
                  dataIndex="orderCount"
                  className="small-cell"
                />
              </Table>
            </div>
          </SearchResult>
        </Spin>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
          pageSize={STOCK_PAGE_ITEMS}
        />
      )}
      {selectedStock && (
        <StockChangeModal
          selectedStock={selectedStock}
          onOk={async (pNo, stockQuantity, setModalLoading) => {
            setLoading(true)
            setModalLoading(true)
            const response = await stockApi.manualUpdate({pNo, stockQuantity})
            if (response.status === RESPONSE_OK) {
              setSelectedStock(null)
              notifySuccess({ message: '在庫を更新しました。' })
              setChangeStockFlg(!changeStockFlg)
            } else {
              notifyError({ message: '在庫の更新に失敗しました。' })
            }
            setModalLoading(false)
            setLoading(false)
          }}
          onCancel={() => {
            setSelectedStock(null)
          }}
        ></StockChangeModal>
      )}
    </Page>
  )
}

export default StockSearch
