import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import Input from './components/Input'
import Confirm from './components/Confirm'
import { appNavigate } from '../../components/AppLink'
import usePage from './hooks/page'
import useReturnTable from './hooks/return-table'
import { accept as returnAcceptApi } from '../../api/return-accept'
import orderApi from '../../api/order'
import {
  ORDER_TYPE_CANCEL,
  ORDER_TYPE_CHANGE,
  RESPONSE_OK
} from '../../constants'
import { Spin } from 'antd'

export default function ReturnAccept({ orderId }) {
  const { toInput, toConfirm, isInputPage, isConfirmPage } = usePage()
  const { products, counts, updateCounts } = useReturnTable(orderId)
  const [order, setOrder] = useState(null)

  const [receivedAt, setReceivedAt] = useState(null)
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await orderApi.show(orderId)
      if (response.status === RESPONSE_OK) {
        setOrder(response.data)
      }
      setLoading(false)
    })()
  }, [orderId])

  const submit = async e => {
    e.preventDefault()

    const data = {
      orderId,
      counts: Object.keys(counts).map(pNo => ({ pNo, ...counts[pNo] })),
      receivedAt,
      comment
    }

    setLoading(true)
    await returnAcceptApi(data)
    setLoading(false)

    // 注文種別ごとに遷移先が異なる
    const pathSuffix = (() => {
      if (order.typeCode === ORDER_TYPE_CHANGE) return '/exchange'
      if (order.typeCode === ORDER_TYPE_CANCEL) return '/return'
      return ''
    })()

    appNavigate(`/orders/${orderId}${pathSuffix}`)
  }

  return (
    <Page title="返品処理">
      {isInputPage && (
        <Spin spinning={loading}>
          <Input
            products={products}
            counts={counts}
            comment={comment}
            receivedAt={receivedAt}
            onCountChange={updateCounts}
            onCommentChange={setComment}
            onReceivedAtChange={setReceivedAt}
            onConfirm={toConfirm}
          />
        </Spin>
      )}

      {isConfirmPage && (
        <Confirm
          products={products}
          counts={counts}
          comment={comment}
          receivedAt={receivedAt}
          onBack={toInput}
          onSubmit={submit}
          loading={loading}
        />
      )}
    </Page>
  )
}
