import React, { useEffect, useMemo, useState } from 'react'
import { Redirect } from '@reach/router'
import { useDispatch } from 'react-redux'
import { Form, Input, Button, Row, Col, Spin } from 'antd'
import { login, useLoginLoading, useLoginError, useLoginLock } from '../store/auth'
import logoApi from '../api/logo'
import accountsApi from '../api/accounts'
import { RESPONSE_OK } from '../constants'
import { mailAddressRegEx } from '../validator'

const LOGIN = 'ログイン'
const PASSWORD_CHANGE_MAIL_INPUT = 'メールアドレス入力'
const PASSWORD_CHANGE_MAIL_SENDED = 'メール送信完了'

function Login({ companyCode }) {
  const [scene, setScene] = useState(LOGIN)
  const isCurrentScene = (element) => element === scene

  return (
    <div>
      {isCurrentScene(LOGIN) &&
        <LoginScene
          companyCode={companyCode}
          setScene={setScene}
        />
      }
      {isCurrentScene(PASSWORD_CHANGE_MAIL_INPUT) &&
        <PasswordChangeMailInput
          setScene={setScene}
        />
      }
      {isCurrentScene(PASSWORD_CHANGE_MAIL_SENDED) &&
        <PasswordChangeMailSended
          setScene={setScene}
        />
      }
    </div>
  )
}

function LoginScene({ companyCode, setScene }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [logo, setLogo] = useState('')

  useEffect(() => {
    if (!companyCode) return () => {}
    (async () => {
      const response = await logoApi.get(companyCode)
      setLogo(response.data.logoImage)
    })()
  }, [companyCode])

  const dispatch = useDispatch()
  const isLoginLoading = useLoginLoading()
  const isLoadingError = useLoginError()
  const isAccountLock = useLoginLock()

  if (companyCode === 'dmm') {
    return <Redirect to={`/unif/login`} noThrow />
  }

  const submit = () => {
    if (email === '' || password === '') {
      return false
    }
    dispatch(login({ mailAddress: email, password }))
  }

  return (
    <div className="login">
      <div className="main">
        <div className="mb-4">
          <Row type="flex" justify="center">
            <Col span={18}>
              <img src={`${logo}?t=${(new Date()).getTime()}`} alt="" style={{ width: '100%' }} />
            </Col>
          </Row>
        </div>
        {isLoadingError && <FailedMessage />}
        {isAccountLock && <AccountLockMessage />}
        <Form layout="vertical">
          <Form.Item
            label="メールアドレス"
            validateStatus={(isLoadingError || isAccountLock) ? 'error' : ''}
          >
            <Input
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="パスワード"
            validateStatus={(isLoadingError || isAccountLock) ? 'error' : ''}
          >
            <Input.Password
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </Form.Item>
          <div className="text-center mt-2">
            <Button type="primary" onClick={submit} loading={isLoginLoading}>
              ログイン
            </Button>
          </div>
          <div className="text-center mt-2">
            <Button type="link" onClick={() => setScene(PASSWORD_CHANGE_MAIL_INPUT)} >
              <span>パスワードを忘れた方はこちら</span>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

function PasswordChangeMailInput({ setScene }) {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)
  const isValidInputEmail = () => email.match(mailAddressRegEx)
  const sendMailAddress = async () => {
    setLoading(true)
    if (email === '') { return }
    const response = await accountsApi.passwordChangeSendMail({mailAddress: email})
    if (response.status === RESPONSE_OK) {
      setScene(PASSWORD_CHANGE_MAIL_SENDED)
    } else {
      setIsInvalidEmail(true)
    }
    setLoading(false)
  }
  const isValidEmail = useMemo(() => {
    return isValidInputEmail() && !isInvalidEmail
  }, [email, isInvalidEmail])

  return (
    <div className='password-change-mail-input'>
      <div className="main">
        <Spin spinning={loading}>
          <Row type="flex" justify="center">
            <Col span={18}>
              <h1 className="text-center text-bold text-md">パスワード変更</h1>
            </Col>
            <Col className="mb-3" style={{ padding: 8 }}>
              パスワード変更用のURLリンクを送付します<br/>
              アカウントのメールアドレスを入力して下さい
            </Col>
          </Row>
          <Form layout="vertical">
            {isInvalidEmail && <AccountNotFoundMessage />}
            <Form.Item
              label="メールアドレス"
              help={(isValidEmail || email.length < 1) ? "" : <div>
                <div> 適切なメールアドレスを入力してください</div>
              </div>}
              validateStatus={(isValidEmail || email.length < 1) ? '' : 'error'}
            >
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </Form.Item>
            <Row style={{ paddingTop: 20 }} type="flex" align="middle" justify="space-between">
              <Button type="link" onClick={() => setScene(LOGIN)} >
                ログイン画面へ戻る
              </Button>
              <Button
                type="primary"
                onClick={() => {sendMailAddress()} }
                disabled={!isValidInputEmail()}
              >
                メールを送信する
              </Button>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  )
}

function PasswordChangeMailSended({ setScene }) {
  return (
    <div className='password-change-mail-input'>
      <div className="main">
        <Row type="flex" justify="center">
          <Col>
            <h1 className="text-center text-bold text-md">メールを送信しました</h1>
          </Col>
          <Col className="text-sm" style={{ paddingTop: 16 }}>
            送付されたメールアドレス記載のURLに遷移して、<br/>パスワード変更を実施して下さい
          </Col>
        </Row>
        <div className="text-center mt-2">
          <Button type="link" onClick={() => setScene(LOGIN)} >
              ログイン画面へ戻る
          </Button>
        </div>

      </div>
    </div>
  )
}

function FailedMessage() {
  return (
    <div className="message is-danger mb-3">
      メールアドレス・パスワードに誤りがあるか、登録されていません。
    </div>
  )
}

function AccountNotFoundMessage() {
  return (
    <div className="message is-danger mb-3">
      入力されたメールアドレスの、アカウント情報がありません。<br/>
      正しいメールアドレスを入力して再度実施して下さい。
    </div>
  )
}

function AccountLockMessage() {
  return (
    <div className="message is-danger mb-3">
      セキュリティ上の理由によりアカウントにロックがかかりました。
      下記の「パスワードを忘れた方はこちら」からパスワードを変更してください。
    </div>
  )
}

export default Login
