import React from 'react'
import { Table } from 'antd'

// 利用状況リストテーブル
const UsageStatusAndNextDeliveryListTable = ({ dataSource, rowSelection}) => {
  return (
    <Table bordered pagination={false} dataSource={dataSource} size="small" rowKey={record => record.itemStatusId} rowSelection={rowSelection}>
      <Column title="利用商品名" dataIndex="useItemTitle" key="useItemTitle" width='30%' render={renderRow} />
      <Column title="サイズ" dataIndex="useItemSize" key="useItemSize" width='7.5%' align="center" render={renderRowCenter} />
      <Column title="数量" dataIndex="useItemCount" key="useItemCount" width='7.5%' align="center" render={(record) => renderRowCenter(<span className="text-sm">{record}</span>)} />
      <Column title="初回注文日" dataIndex="firstOrderDate" key="firstOrderDate" width='9%' align="center" render={(record) => renderRowCenter(record)} />
      <Column title="次回配送月" key="nextDeliveryMonth" width='9%' align="center" render={(record) => {
        return record.existsNextDelivery ?
          renderRowCenter(record.isSuspendedDelivery ? '停止済み': record.nextDeliveryMonth):
          renderRowCenter('ー')
      }}/>
      <Column title="次回配送サイズ" key="nextDeliveryItemSize" width='12.5%' align="center" render={(record) => {
        return record.existsNextDelivery ?
          renderRowCenter(record.isSuspendedDelivery ? '停止済み': record.nextDeliveryItemSize):
          renderRowCenter('ー')
      }}/>
      <Column title="次回配送数量" key="nextDeliveryItemCount" width='12.5%' align="center" render={(record) => {
        return record.existsNextDelivery ?
          renderRowCenter(record.isSuspendedDelivery ? '停止済み': record.nextDeliveryItemCount):
          renderRowCenter('ー')
      }}/>
      <Column title="配送変更期限日" key="closingDate" width='12%' align="center" render={(record) => {
        return record.existsNextDelivery ?
          renderRowCenter(record.closingDate):
          renderRowCenter('ー')
      }}/>
    </Table>
  )
}
const { Column } = Table
const renderRow = (element) => (<span><div className="text-paragraph">{element}</div></span>)
const renderRowCenter = (element) => (<span><div style={{textAlign: "center"}} className="text-paragraph">{element}</div></span>)

export default UsageStatusAndNextDeliveryListTable
