import cartApi from '../../api/cart'

export default async function fetchShippingAddresses(myStoreId) {
  const response = await cartApi.shippingAddresses()

  const addresses = response.data

  const myAddress = addresses.find(addr => addr.storeId === myStoreId)

  return [myAddress, ...addresses.filter(addr => addr.storeId !== myStoreId)]
}
