import { useEffect, useState } from 'react'
import accountsApi from '../api/accounts'

export default function useCompanyStoreSelect({ isChildStore, initialStoreId=null, initialCompanyId=null, isReturnToPage=false }) {
  const [companies, setCompanies] = useState([])
  const [stores, setStores] = useState([])
  const [companyId, setCompanyId] = useState(initialCompanyId)
  const [storeId, setStoreId] = useState(initialStoreId)
  const [loading, setLoading] = useState(false)

  const withLoading = async callback => {
    setLoading(true)
    await callback()
    setLoading(false)
  }

  useEffect(() => {
    if (isChildStore) return () => {}

    (async () => await withLoading(async () => {
      const response = await accountsApi.companies()
      setCompanies(response.data)
    }))()
  }, [isChildStore])

  useEffect(() => {
    if (isChildStore) return () => {}

    if (companyId != null) {
      (async () => await withLoading(async () => {
        const response = await accountsApi.stores(companyId)
        setStores(response.data)
        if(isReturnToPage) {
          setStoreId(initialStoreId)
        } else {
          setStoreId(null)
        }

      }))()
    } else {
      (async () => await withLoading(async () => {
      const response = await accountsApi.stores(0)
      setStores(response.data)
      if(isReturnToPage) {
        setStoreId(initialStoreId)
      } else {
        setStoreId(null)
      }
      }))()
    }
  }, [companyId, isChildStore])

  return {
    companies,
    stores,
    companyId,
    setCompanyId,
    storeId,
    setStoreId,
    loading
  }
}
