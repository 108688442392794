import { useEffect, useState } from 'react'
import transferOrderApi from '../../../api/transfer-order'

export default function useFetchTransferOrder({ id, setLoading }) {
  const [order, setOrder] = useState(null)
  const [products, setProducts] = useState([])

  const refreshOrder = async (id) => {
    const result = await transferOrderApi.show(id)

    if (result) {
      const { details:products , ...orderInfo } = result.data
      setOrder(orderInfo)
      setProducts(products)
    }
  }

  useEffect(() => {
    if (!id) return () => {}
    (async () => {
      setLoading(true)
      await refreshOrder(id)
      setLoading(false)
    })()
  }, [id])

  const patchOrder = patch => setOrder(Object.assign({}, order, patch))

  return { order, products, patchOrder, refreshOrder }
}
