import cartApi from '../../api/cart'
import { RESPONSE_OK } from '../../constants'
import Product from '../../models/Product'

export default async function fetchCart() {
  const response = await cartApi.index()

  if (response.status !== RESPONSE_OK) {
    return false
  }

  const { deliveryFee, carts, shippingMessage, showDetailMemo, taxAmount } = response.data

  return {
    carts: carts.map(({ cartId, orderCount, ...product }) => {
      const { productId, title, images, unitName, ...details } = product
      const newProduct = new Product({ productId, title, images, unitName, details })
      return { product: newProduct, cartId, orderCount }
    }),
    shippingCost: deliveryFee * 1,
    shippingMessage,
    showDetailMemo,
    taxAmount: taxAmount,
  }
}
