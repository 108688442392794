import { useSelector } from 'react-redux'
import { 
  ROLE_ADMIN, ROLE_BUYER, ROLE_SELLER,
  USAGE_STATUS_NONE, USAGE_STATUS_TYPE1, USAGE_STATUS_TYPE2
} from '../constants'
import auth from '../api/auth'

export const useAuth = () => useSelector(state => state.auth.user !== null)

export const useCurrentUser = () => useSelector(state => state.auth.user)

export const useIsAdmin = () => useSelector(({ auth: authState }) => {
  if (authState.user === null) {
    return false
  }

  return authState.user.roleId === ROLE_ADMIN
})

export const useIsBuyer = () => useSelector(({ auth: authState }) => {
  if (authState.user === null) {
    return false
  }

  return authState.user.roleId === ROLE_BUYER
})

export const useIsParentStore = () => useSelector(({ auth: authState }) => {
  return authState.user ? authState.user.isParentStore : false
})

export const useIsChildStore = () => useSelector(({ auth: authState }) => {
  return authState.user === null
    ? false
    : !authState.user.isParentStore && !authState.user.isCompanyAdmin
})

export const useIsCompanyAdmin = () => useSelector(({ auth: authState }) => {
  return authState.user === null ? false : authState.user.isCompanyAdmin
})

export const useIsSellerAdmin = () => useSelector(({ auth: authState }) => {
  return authState.user === null
    ? false
    : authState.user.roleId === ROLE_SELLER && authState.user.isCompanyAdmin
})

export const companyCodeSelector = state => {
  return state.auth.user ? state.auth.user.company.url : ''
}

export const useCompanyCode = () => useSelector(companyCodeSelector)

export const useShowProductStock = () => useSelector(({ auth: authState }) => {
  return authState.user === null ? false : !authState.user.isRental
})

export const useIsRental = () => useSelector(({ auth: authState }) => {
  return authState.user === null
    ? false : authState.user.isRental || authState.user.roleId === ROLE_ADMIN
  })

export const useShowPrice = () => useSelector(({ auth: authState }) => {
  return authState.user === null ? false : !authState.user.isShowPrice
})

export const useIsStatusUse = () => useSelector(({ auth: authState }) => {
  if (authState.user === null) { return false }
  switch (authState.user.company.isStatusUse) {
    case USAGE_STATUS_NONE:
      return false
    case USAGE_STATUS_TYPE1:
    case USAGE_STATUS_TYPE2:
      return true
    default:
      return false
  }
})

export const useStatusUseType = () => useSelector(({ auth: authState }) => {
  const isStatusUseType = (element) => {
    if (authState.user === null) {
      return false
    }
    return element === authState.user.company.isStatusUse
  }
  return { isStatusUseType }
})

export const useBillingSummaryType = () => useSelector(({ auth: authState }) => {
  const isBillingSummaryTypeWith = (element) => {
    if (authState.user === null) {
      return false
    }
    return element === authState.user.store.billingSummaryType
  }
  return { isBillingSummaryTypeWith }
})

export const usePaymentSummaryType = () => useSelector(({ auth: authState }) => {
  const isPaymentSummaryTypeWith = (element) => {
    if (authState.user === null) {
      return false
    }
    return element === authState.user.store.paymentSummaryType
  }
  return { isPaymentSummaryTypeWith }
})

export const useShowShipmentFee = () => useSelector(({ auth: authState }) => {
   return authState.user === null ? false : authState.user.company.isCalcShipmentFee
})