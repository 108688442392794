import React, { useEffect, useState } from 'react'
import { Affix, Button, Card, Col, Divider, Icon, Row } from 'antd'
import Image from '../../../components/Image'
import ShippingInfo from '../../Cart/components/ShippingInfo'
import { useCurrentUser, useIsStatusUse } from '../../../hooks/auth'
import fetchShippingAddresses from '../../Cart/fetch-shipping-addresses'
import useDeliveryTimes from '../../../hooks/delivery-times'
import DeliveryTimePanel from '../../../components/DeliveryTimePanel'
import {
  exchangeDeliveryFee as exchangeDeliveryFeeApi
} from '../../../api/rental'
import { numFormat } from '../../../formatter'

const fetchDeliveryFee = async (data, onSuccess) => {
  const response = await exchangeDeliveryFeeApi(data)
  onSuccess(response.data.deliveryFee)
}

export default function Step3({
  product,
  reason,
  reasonName,
  comment,
  exchangeItems,
  onSubmit,
  onPrevClick,
}) {
  const user = useCurrentUser()
  const isStatusUse = useIsStatusUse()
  const [shippingAddresses, setShippingAddresses] = useState([])
  const [shipTo, setShipTo] = useState(null)
  const [shippingCost, setShippingCost] = useState(null)
  const [loading, setLoading] = useState(false)

  const {
    deliveryTimes,
    timeId,
    setTimeId,
    deliveryDate,
    setDeliveryDate,
  } = useDeliveryTimes()

  useEffect(() => {
    (async () => {
      const addresses = await fetchShippingAddresses(user.store.storeId)
      setShippingAddresses(addresses)
      setShipTo(addresses[0])
      await fetchDeliveryFee({
        prefectureId: addresses[0].prefectureId,
        reason,
        exchangeItems: exchangeItems.map((exchangeItem) => {
          return {
            pNo: exchangeItem.exchangeAfterItem.pNo,
            count: exchangeItem.exchangeAfterItem.count
          }
        })
      }, val => setShippingCost(val))
    })()
  }, [])

  const handleShipToChange = async address => {
    setShipTo(address)
    await fetchDeliveryFee({
      prefectureId: address.prefectureId,
      reason,
      exchangeItems: exchangeItems.map((exchangeItem) => {
        return {
          pNo: exchangeItem.exchangeAfterItem.pNo,
          count: exchangeItem.exchangeAfterItem.count
        }
      })
    }, val => setShippingCost(val))
  }

  const submit = async () => {
    setLoading(true)
    await onSubmit({
      exchangeItems: exchangeItems.map((exchangeItem, _) => {
        return {
          befPNo: exchangeItem.pNo,
          // 単品の場合はセット品番は null を渡す
          befSetPNo: product.isSet ? product.pNo : null,
          aftPNo: exchangeItem.exchangeAfterItem.pNo,
          orderCount: exchangeItem.count,
          setOrderCount: exchangeItem.setCount
        }
      }),
      reason,
      comment,
      deliveryDate,
      deliveryTimeId: timeId,
      ...shipTo,
    })
    setLoading(false)
  }

  return (
    <>
      <Row gutter={40}>
        <Col span={16}>
          <div className="mb-4">
            {shipTo && (
              <ShippingInfo
                user={user}
                shippingAddresses={shippingAddresses}
                currentShipIndex={shipTo}
                shipTo={shipTo}
                onShipChange={handleShipToChange}
              />
            )}
          </div>
          <p className="text-bold mb-1">申し込み内容</p>
          <p className="mb-2">
            交換内容により、セット商品が変わる場合があります。交換後のレンタル商品はレンタル状況ページにてご確認ください。
          </p>
          {exchangeItems.map((exchangeItem, _) => {
            const exchangeAfterItem = exchangeItem.exchangeAfterItem
            const hasSetChange = product.isSet && (exchangeItem.size !== exchangeAfterItem.size)
            return (
              <Card className="mb-4" style={{marginBottom: "24px"}}>
                <Card size="small">
                  <p>
                    <span>{product.productTitle}</span>
                    {product.isSet && (
                      <>
                        <span className="text-mute ml-1 mr-1">&gt;</span>
                        <span>{product.title}</span>
                      </>
                    )}
                  </p>
                  <div className="mt-1">
                    {product.isSet && (
                      <>
                        <span>{exchangeItem.title}</span>
                        <Divider type="vertical" />
                      </>
                    )}
                    <span>{exchangeItem.size}</span>
                    <Divider type="vertical" />
                    <span>{exchangeItem.pNo}</span>
                  </div>
                </Card>
                <div className="text-center mt-1 mb-1">
                  <Icon type="arrow-down" style={{ fontSize: 16 }} />
                </div>
                <Card size="small">
                  <Row gutter={16} type="flex" align="middle">
                    <Col span={4}>
                      <Image src={product.image ? product.image.imageName : null} />
                    </Col>
                    <Col span={16}>
                      <p className="mb-1">
                        <span>{product.productTitle}</span>
                        {product.isSet && (
                          <>
                            <span className="text-mute ml-1 mr-1">&gt;</span>
                            <span>{exchangeAfterItem.title}</span>
                          </>
                        )}
                      </p>
                      <div className="mb-1 text-header">
                        <span>{exchangeAfterItem.productTitle}</span>
                        <Divider type="vertical" />
                        <span>{exchangeAfterItem.size}</span>
                      </div>
                      <p style={{ marginBottom: 0 }}>{exchangeAfterItem.pNo}</p>
                    </Col>
                    <Col span={4} className="text-right">数量：{exchangeAfterItem.count}</Col>
                  </Row>
                  <div className="panel is-narrow mt-2">
                    <ul className="plain-list">
                      <li>
                        <Row>
                          <Col span={6}>交換理由</Col>
                          <Col span={18}>
                            <p style={{ marginBottom: 0 }}>{reasonName}</p>
                            {comment && <p className="mt-1">{comment}</p>}
                          </Col>
                        </Row>
                      </li>
                      {hasSetChange && (
                        <li>
                          <Row>
                            <Col span={6}>セット変更</Col>
                            <Col span={18}>
                              <span>{product.title}</span>
                              <span className="mr-1 ml-1">→</span>
                              <span>
                                {exchangeAfterItem.title}（{exchangeItem.setCount}セット）
                              </span>
                            </Col>
                          </Row>
                        </li>
                      )}
                    </ul>
                  </div>
                </Card>
              </Card>
            )
          })}
        </Col>

        <Col span={8}>
          {deliveryTimes.length > 0 && (
            <DeliveryTimePanel
              deliveryTimes={deliveryTimes}
              onTimeChange={setTimeId}
              onDateChange={setDeliveryDate}
            />
          )}

          <Affix offsetTop={16}>
            <div className="panel">
              <div className="mb-4">
                <Row type="flex" justify="space-between" align="middle">
                  <Col>送料</Col>
                  <Col>
                    <span className={`text-bold ${isStatusUse ? " text-sm": "text-md"}`}>
                      {isStatusUse ? "※支払い情報でご確認ください" : `${numFormat(shippingCost)} 円`}
                    </span>
                  </Col>
                </Row>
              </div>
              <Button type="primary" block onClick={submit} loading={loading}>
                交換を確定する
              </Button>
            </div>
            <div className="mt-2 text-center">※ 現在表記している金額はインボイス（適格請求書）ではございません。参考金額となります。インボイス（適格請求書）は従来通りの方法でご提供いたします。</div>
          </Affix>
        </Col>
      </Row>

      <Divider />

      <Button onClick={onPrevClick} disabled={loading}>
        <Icon type="left" />
        戻る
      </Button>
    </>
  )
}
