import httpClient from '../http'

export default {
  search(params) {
    return httpClient.get(`/api/transfer`, { params })
  },

  show(id) {
    return httpClient.get(`/api/transfer/${id}`)
  },

  approve(id) {
    return httpClient.put(`/api/transfer/${id}/approval`)
  },
}
