import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd'
import Pagination from './Pagination'
import SearchResult from './SearchResult'
import AppLink from './AppLink'
import ProductCell from './ProductCell'
import scrollTop from '../scroll-top'
import productApi from '../api/product'
import { RESPONSE_OK, PRODUCT_PAGE_ITEMS } from '../constants'
import Product from '../models/Product'
import { useShowProductStock, useShowPrice } from '../hooks/auth'

function ProductSearchBody({ renderDetailLink, accountId = null }) {
  const initialQuery = Object.assign(
    { name: '', page: 1 },
    accountId ? { accountId } : {}
  )

  const [query, setQuery] = useState(initialQuery)
  const [products, setProducts] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const showProductStock = useShowProductStock()
  const showPrice = useShowPrice()

  useEffect(() => {
    (async () => {
      scrollTop()
      setLoading(true)
      const response = await productApi.search(query)
      if (response.status === RESPONSE_OK) {
        const { products: items, ...rest } = response.data
        setProducts(items === null ? [] : items.map(p => new Product(p)))
        setPageInfo(rest)
      }
      setLoading(false)
    })()
  }, [query])

  const handleChange = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = async (name, pNo) => {
    setQuery(Object.assign({}, query, { name, pNo, page: 1 }))
  }

  return (
    <>
      <SearchPanel onSearch={handleSearch} />

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handleChange}
          pageSize={PRODUCT_PAGE_ITEMS}
        />
      )}

      <Spin spinning={loading}>
        <div className="mt-3 mb-3">
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="商品が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            <Row gutter={16} type="flex">
              {products.map(product => (
                <Col key={product.productId} span={6}>
                  <div style={{
                    display: `flex`,
                    flexDirection: `column`,
                    height: `100%`
                  }}>
                    <AppLink
                      to={renderDetailLink(product.productId)}
                      style={{ flex: 1 }}
                    >
                      <ProductCell
                        name={product.title}
                        image={product.mainImageUrl}
                        sex={product.sexLabel}
                        minPrice={product.taxInMinPrice}
                        maxPrice={product.taxInMaxPrice}
                        stock={showProductStock ? product.typeLabel : null}
                        showPrice={showPrice}
                      />
                    </AppLink>
                    <Divider />
                  </div>
                </Col>
              ))}
            </Row>
          </SearchResult>
        </div>
      </Spin>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handleChange}
          pageSize={PRODUCT_PAGE_ITEMS}
        />
      )}
    </>
  )
}

function SearchPanel({ onSearch }) {
  const formItemStyle = { marginBottom: 0, paddingBottom: 0 }

  const [name, setName] = useState('')
  const [pNo, setPNo] = useState('')

  const handleSubmit = e => {
    e.preventDefault()
    onSearch(name, pNo)
  }

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <div className="panel">
        <Row gutter={24} type="flex" align="middle">
          <Col span={12}>
            <Form.Item label="商品名" style={formItemStyle}>
              <Input
                allowClear
                value={name}
                onChange={event => setName(event.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="品番" style={formItemStyle}>
              <Input
                allowClear
                value={pNo}
                onChange={event => setPNo(event.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="mt-3 mb-3 text-center">
        <Button type="primary" htmlType="submit">
          検索する
        </Button>
      </div>
    </Form>
  )
}

export default ProductSearchBody
