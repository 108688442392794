import React from 'react'
import { Typography, Divider } from 'antd'
import Image from './Image'
import { numFormat } from '../formatter'

const { Text } = Typography

const Yen = () => <Text className="text-sm" style={{ marginLeft: 4 }}>円</Text>

function ProductCell({ image, name, minPrice, maxPrice, sex, stock, showPrice }) {
  return (
    <div>
      <div className="mb-2">
        <Image src={image} />
      </div>
      <div className="mb-1">
        <div className="text-bold text-primary">{name}</div>
      </div>
      <div className="mb-1">
        {!showPrice && (
          <>
            <Text strong>{numFormat(minPrice)}</Text>
            <Yen />
            <Text className="text-sm">（税込）</Text>
            {maxPrice > minPrice && (
              <>
                <Text className="ml-1 mr-1">〜</Text>
                <Text strong>{numFormat(maxPrice)}</Text>
                <Yen />
                <Text className="text-sm">（税込）</Text>
              </>
            )}
          </>
        )}

      </div>
      <div>
        <Text type="secondary">{sex}</Text>
        {stock && (
          <>
            <Divider type="vertical" />
            <Text type="secondary">{stock}</Text>
          </>
        )}
      </div>
    </div>
  )
}

export default ProductCell
