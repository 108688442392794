import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
  Row,
  Col,
  List,
  Icon,
  Select,
  Spin
} from 'antd'
import Page from '../components/Page'
import LinkButton from '../components/LinkButton'
import Pagination from '../components/Pagination'
import SearchResult from '../components/SearchResult'
import accountsApi from '../api/accounts'
import { RESPONSE_OK } from '../constants'
import useCompanyStoreSelect from '../hooks/company-store-select'
import queryString from "query-string"
import { appNavigate } from '../components/AppLink'

const { Option } = Select

const inputStyle = { width: '100%' }

function AccountsSearch(props) {

  const searchParams = queryString.parse(props.location.search)
  const pagePath = "/accounts?"

  const initialQuery = {
    companyId: searchParams.companyId * 1 || null,
    storeId: searchParams.storeId * 1 || null,
    page: searchParams.page * 1 || 1,
  }

  const isReturnToPage = searchParams.storeId != null

  return (
    <Page title="販売先アカウント一覧">
      <AccountSearchList
        initialQuery={initialQuery}
        pagePath={pagePath}
        isReturnToPage={isReturnToPage}
        renderList={(accounts, query) => (
          <List
            itemLayout="horizontal"
            dataSource={accounts}
            renderItem={item => renderItem(item, query)}
          />
        )}
      />
    </Page>
  )
}

export function AccountSearchList({ renderList, initialQuery, pagePath, isReturnToPage }) {
  const [pageInfo, setPageInfo] = useState(null)
  const [accounts, setAccounts] = useState([])
  const [query, setQuery] = useState(initialQuery)
  const [loading, setLoading] = useState(false)
  const {
    companies, stores, companyId, setCompanyId, storeId, setStoreId
  } = useCompanyStoreSelect({ isChildStore: false, initialCompanyId: initialQuery.companyId, initialStoreId: initialQuery.storeId, isReturnToPage })

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await accountsApi.index(query)
      if (response.status === RESPONSE_OK) {
        const { results, ...rest } = response.data
        setAccounts(results)
        setPageInfo(rest)
      }
      setLoading(false)
    })()
  }, [query])

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    const params = {
      companyId,
      storeId,
      page: 1,
    }
    setQuery(Object.assign({}, query, params))
    appNavigate(pagePath + queryString.stringify(params, { skipEmptyString: true }))
  }

  return (
    <>
      <div className="mb-3">
        <Form
          layout="vertical"
          colon={false}
          labelAlign="left"
          onSubmit={handleSearch}
        >
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={12}>
                <Form.Item label="企業名">
                  <Select
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    style={inputStyle}
                    value={companyId}
                    onChange={val => setCompanyId(val)}
                  >
                    {companies.map(item => (
                      <Option key={item.companyId} value={item.companyId}>
                        {item.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="店舗名">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    style={inputStyle}
                    value={storeId}
                    onChange={val => setStoreId(val)}
                  >
                    {stores.map(item => (
                      <Option key={item.storeId} value={item.storeId}>
                        {item.storeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="mt-3 text-center">
            <Button htmlType="submit" type="primary">
              検索
            </Button>
          </div>
        </Form>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="アカウント情報が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            {renderList(accounts, query)}
          </SearchResult>
        </Spin>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </>
  )
}

function renderItem(item, query) {
  return (
    <List.Item
      actions={[
        <LinkButton
          to={`/accounts/${item.accountId}/products`}
          state={query}
          type="primary" small ghost app
        >
          <span>取扱中の商品を見る</span>
          <Icon type="right" />
        </LinkButton>,
      ]}
    >
      <List.Item.Meta
        title={item.companyName + ' ' + item.storeName}
        description={item.mailAddress}
      />
    </List.Item>
  )
}

export default AccountsSearch
