import httpClient, { fileClient }  from '../http'
import download from '../download'
import { RESPONSE_OK } from '../constants'

export default {
  search(params) {
    return httpClient.get(`/api/item-status`, { params })
  },
  searchProductData(params) {
    return httpClient.get(`/api/item-status/product-data`, { params })
  },

  update(params) {
    return httpClient.post(`/api/next-delivery-change`, params )
  },

  async csv(params) {
    const url = `/api/item-status/downloadcsv`
    const response = await fileClient.get(url, { params })

    if (response.status === RESPONSE_OK) {
      const fileName = `item_status_list.csv`
      download(response.data, fileName)
    }
  },
}