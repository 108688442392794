import React from 'react'
import { Result } from 'antd'

function NotFound() {
  return (
    <Result
      status="warning"
      title="お探しのページは見つかりませんでした。"
    >
      <div className="text-center text-paragraph">
        アクセスしたページは削除されたかURLが変更されているため、見つけることができません。
      </div>
    </Result>
  )
}

export default NotFound
