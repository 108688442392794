import React from 'react'
import { Button, Col, Divider, Form, Row, Table } from 'antd'
import moment from 'moment'
import { DATE_FORMAT } from '../../../constants'

const { Column } = Table

export default function ReturnAcceptConfirm({
  products,
  counts,
  comment,
  receivedAt,
  onBack,
  onSubmit,
  loading
}) {
  return (
    <Form onSubmit={onSubmit}>
      <div className="mb-4">
        <Table
          bordered
          pagination={false}
          dataSource={products}
          rowKey="pNo"
        >
          <Column
            key="product"
            title="商品"
            render={(_, record) => (
              <div className="text-paragraph">
                <div>
                  {record.productTitle}
                  <Divider type="vertical" />
                  {record.title}
                </div>
                <div>
                  {record.pNo}
                  <Divider type="vertical" />
                  {record.size}
                </div>
              </div>
            )}
          />
          <Column
            key="orderCount"
            title="申込数"
            dataIndex="orderCount"
            className="min-cell"
          />
          <Column
            key="ok"
            title="良品数"
            className="min-cell success-cell"
            render={(_, { pNo }) => counts[pNo].ok}
          />
          <Column
            key="repair"
            title="修繕数"
            className="min-cell warning-cell"
            render={(_, { pNo }) => counts[pNo].repair}
          />
          <Column
            key="discard"
            title="破棄数"
            className="min-cell error-cell"
            render={(_, { pNo }) => counts[pNo].discard}
          />
        </Table>
      </div>

      <Row gutter={[0, 12]}>
        <Col span={6}>入庫予定日（良品のみ）</Col>
        <Col span={18}>
          {receivedAt ? moment(receivedAt).format(DATE_FORMAT) : '-'}
        </Col>
        <Col span={6}>備考</Col>
        <Col span={18}>{comment || '-'}</Col>
      </Row>

      <Divider />

      <Row>
        <Col span={12}>
          <Button onClick={onBack}>戻る</Button>
        </Col>
        <Col span={12} className="text-right">
          <Button type="primary" htmlType="submit" loading={loading}>
            返品対応を完了する
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
