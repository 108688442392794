import React, { useEffect, useState } from 'react'
import { Col, List } from 'antd'
import Page from '../components/Page'
import guideApi from '../api/guide'
import { RESPONSE_INVALID, RESPONSE_OK } from '../constants'

function Guide() {
  const [guides, setGuides] = useState([])
  const [errorMsg, setErrorMsg] = useState(null)

  useEffect(() => {
    (async () => {
      const response = await guideApi.index()
      if (response.status === RESPONSE_OK) {
        const { guides: results } = response.data
        setGuides(results)
      }
    })()
  }, [])

  return (
    <Page title="ヘルプ・規約">
      {errorMsg == null && (
        <div className="mb-1">
          <p className="text-md mb-3">UNI-STOCKの利用方法および利用に関しての注意事項や規約となります。
          <br/>ご利用前に必ずご確認ください。</p>
          <List
            bordered
            itemLayout="horizontal"
            dataSource={guides}
            renderItem={item => renderItem(item, setErrorMsg)}
          />
        </div>
      )}
      {errorMsg != null && (
        <div className="text-md">
          <Col className="text-red">{errorMsg}</Col>
        </div>
      )}
    </Page>
  )
}

function renderItem(item, setErrorMsg) {

  const showGuide = async id => {
    const response = await guideApi.show(item.id)
  
    if (response.status === RESPONSE_OK) {
      window.open(response.data.objectPath, '_blank')
    } else if (response.status === RESPONSE_INVALID) {
      setErrorMsg('参照権限がありません')
    }
  }
  
  return (
    <List.Item>
        <a onClick={showGuide}>{item.fileName}</a>
    </List.Item>
  )
}

export default Guide
