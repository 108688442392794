import React from 'react'
import { Modal } from 'antd'

// 移動申請承認モーダル
export default function ApproveModal({ id, visible, onOk, onCancel }) {
  return (
    <Modal
      title="申請を承認する"
      visible={visible}
      okText="申請を承認する"
      cancelText="戻る"
      onOk={onOk}
      onCancel={onCancel}
    >
      <p className="mb-2">申請番号：{id}</p>
      <p className="mb-2">
        申請の承認を行うと注文が確定されます。
      </p>
      <p>この申請の承認を行いますか？</p>
    </Modal>
  )
}