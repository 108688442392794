import { useEffect, useState } from 'react'
import storeApi from '../api/store'

export default function useForumTypeSelect() {
  const [stores, setStores] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const response = await storeApi.myCompanyStore()
      setStores(response.data)
    }
    fetch()
  }, [])

  return stores
}
