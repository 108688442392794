import React, { useState } from 'react'
import { Card, Button, Form, Row, Col, DatePicker, Spin } from 'antd'
import moment from 'moment'
import Page from '../components/Page'
import { MONTH_FORMAT } from '../constants'
import csvApi from '../api/csv'

const { MonthPicker } = DatePicker
const inputStyle = { width: '100%' }

function useCsvDownload(downloadFunc) {
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(moment().add(-1,'M'));

  const download = async () => {
    if (!month) {
      return false
    }

    setLoading(true)
    const yearMonth = month.format('YYYY-MM').split('-')
    downloadFunc(...yearMonth)
    setLoading(false)
  }

  const monthPickerProps = {
    value: month,
    onChange: val => setMonth(val),
  }

  return [loading, monthPickerProps, download]
}

function useCsvTemplateDownload(downloadFunc) {
  const [loading, setLoading] = useState(false)

  const download = async (templateName) => {
    setLoading(true)
    downloadFunc(templateName)
    setLoading(false)
  }
  return [loading, download]
}

function CSVDownload() {
  return (
    <Page title="CSVダウンロード">
      <div className="mb-3">
        <BillingForAccounting />
      </div>
      <div className="mb-3">
        <BillingForRakuichi />
      </div>
      <div className="mb-3">
        <ReceiveIssueTemplate />
      </div>
      <div className="mb-3">
        <ReceiveWarehousingTemplate />
      </div>
    </Page>
  )
}

function ReceiveIssueTemplate() {
  const { receiveIssue: downloadFunc } = csvApi
  const [loading, download] = useCsvTemplateDownload(downloadFunc)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">出庫確定CSV（テスト用）</p>
          <p>出庫確定(テスト用)のためのデータのダウンロードを行います。</p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={() => download('t2k')}
                  loading={loading}
                >
                  T2K倉庫用 CSVダウンロード
                </Button>
                <br/>
                <br/>
                <Button
                  type="primary"
                  block
                  onClick={() => download('高島屋_日本物流')}
                  loading={loading}
                >
                  日本物流(髙島屋)倉庫用 CSV DL
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function ReceiveWarehousingTemplate() {
  const { receiveWarehousing: downloadFunc } = csvApi
  const [loading, download] = useCsvTemplateDownload(downloadFunc)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">入庫確定CSV（テスト用）</p>
          <p>入庫確定(テスト用)のためのデータのダウンロードを行います。</p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={() => download('t2k')}
                  loading={loading}
                >
                  T2K倉庫用 CSVダウンロード
                </Button>
                <br/>
                <br/>
                <Button
                  type="primary"
                  block
                  onClick={() => download('高島屋_日本物流')}
                  loading={loading}
                >
                  日本物流(髙島屋)倉庫用 CSV DL
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function WarehousePayment() {
  const { warehousePayment: downloadFunc } = csvApi
  const [loading, monthPickerProps, download] = useCsvDownload(downloadFunc)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">倉庫業者への支払い</p>
          <p>
            DMMから倉庫業者（トポスエンタープライズ）への支払い情報をCSVとして出力する。経費精算の管理は別途ツールで行う。
          </p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <Form.Item label="対象月">
                <MonthPicker
                  format={MONTH_FORMAT}
                  style={inputStyle}
                  {...monthPickerProps}
                />
              </Form.Item>
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={download}
                  loading={loading}
                >
                  CSVダウンロード
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function DeliveryPayment() {
  const { deliveryPayment: downloadFunc } = csvApi
  const [loading, monthPickerProps, download] = useCsvDownload(downloadFunc)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">配送業者への支払い</p>
          <p>
            配送業者（佐川急便）への支払い情報をCSVとして出力。<br/>
            ※実際のダンボールサイズやEXバッグでの発送かどうかまではでないため、実際佐川急便からくる請求情報で実費確認。
          </p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <Form.Item label="対象月">
                <MonthPicker
                  format={MONTH_FORMAT}
                  style={inputStyle}
                  {...monthPickerProps}
                />
              </Form.Item>
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={download}
                  loading={loading}
                >
                  CSVダウンロード
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

/** 経理用_請求明細出力 */
function BillingForAccounting() {
  const { BillingForAccounting: downloadFunc } = csvApi
  const [loading, monthPickerProps, download] = useCsvDownload(downloadFunc)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">経理依頼用_請求明細</p>
          <p>
            請求書発行のための明細が記載されたデータのダウンロードを行います。
          </p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <Form.Item label="対象月">
                <MonthPicker
                  format={MONTH_FORMAT}
                  style={inputStyle}
                  {...monthPickerProps}
                />
              </Form.Item>
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={download}
                  loading={loading}
                >
                  CSVダウンロード
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

/** 楽一用請求明細出力 */
function BillingForRakuichi() {
  const { BillingForRakuichi: downloadFunc } = csvApi
  const [loading, monthPickerProps, download] = useCsvDownload(downloadFunc)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">楽一用_請求明細</p>
          <p>
            楽一取込みのための 請求明細が記載されたデータのダウンロードを行います。
          </p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <Form.Item label="対象月">
                <MonthPicker
                  format={MONTH_FORMAT}
                  style={inputStyle}
                  {...monthPickerProps}
                />
              </Form.Item>
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={download}
                  loading={loading}
                >
                  CSVダウンロード
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}
export default CSVDownload
