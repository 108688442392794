import React, { useState, useMemo } from 'react'
import { Card, Button, Form, Row, Col, DatePicker } from 'antd'
import Page from '../components/Page'
import { RESPONSE_OK, MONTH_FORMAT } from '../constants'
import batchApi from '../api/batch'
import { notifySuccess, notifyError } from '../notify'
import moment from 'moment'

function useBatchExecution(type, params) {
  const batchExecutionHandle = async () => {

    const response = await batchApi.index(type, params)
      if (response.status === RESPONSE_OK){
        notifySuccess({ message: 'バッチ実行リクエストを送信いたしました。' })
      } else {
        notifyError({ message: 'バッチ実行リクエストに失敗しました。' })
      }
    }
  return batchExecutionHandle
}


function BatchAdmin() {


  return (
    <Page title="手動バッチ実行">
      <div className="mb-3">
        <SendWarehousing />
      </div>
      <div className="mb-3">
        <SendIssue />
      </div>
      <div className="mb-3">
        <ReceiveWarehousing />
      </div>
      <div className="mb-3">
        <ReceiveIssue />
      </div>
      <div className="mb-3">
        <ReceiveIssueKm />
      </div>
      <div className="mb-3">
        <UpdateNextDelivery />
      </div>
      <div className="mb-3">
        <NotifyNextDelivery />
      </div>
    </Page>
  )
}

function SendWarehousing() {
const type = 'sendWarehousing'
const batchExecutionHandle = useBatchExecution(type)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">入庫指示</p>
          <p>新規入庫情報を元に、入庫指示CSVを作成しS3にアップロードします。</p>
          <p>定期実行時間：毎日 8:00</p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={batchExecutionHandle}
                >
                  バッチ実行をリクエスト
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function SendIssue() {
const type = 'sendIssue'
const batchExecutionHandle = useBatchExecution(type)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">出庫指示</p>
          <p>新規注文情報を元に、出庫指示CSVを作成しS3にアップロードします。</p>
          <p>定期実行時間：毎日 8:00</p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={batchExecutionHandle}
                >
                  バッチ実行をリクエスト
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function ReceiveIssue() {
const type = 'receiveIssue'
const batchExecutionHandle = useBatchExecution(type)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">出庫確定</p>
          <p>S3から出庫確定CSVを取り込み、注文情報を更新します。</p>
          <p>定期実行時間：毎日 18:10</p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={batchExecutionHandle}
                >
                  バッチ実行をリクエスト
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function ReceiveIssueKm() {
  const type = 'receiveIssue'
  const batchExecutionHandle = useBatchExecution(type, { disableEmail: true })
  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">出庫確定 (KM用メール送信無)</p>
          <p>KM自動注文用の出庫確定バッチ処理</p>
          <p>取り込むデータは、901.syukko_kakutei_km に配置して下さい</p><br/>
          <p>GAINA倉庫の場合は「csv/901.syukko_kakutei_km」</p>
          <p>T2K倉庫の場合は「csv_t2k/901.syukko_kakutei_km」</p><br/>
          <p>（KMの注文情報以外でも出庫確定処理はされます。単純に上記の指定フォルダの出庫確定CSVの内容を処理し、発送完了のメールは送信されない処理です）</p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={batchExecutionHandle}
                >
                  バッチ実行をリクエスト
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function ReceiveWarehousing() {
  const type = 'receiveWarehousing'
  const batchExecutionHandle = useBatchExecution(type)

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">入庫確定</p>
          <p>S3から入庫確定CSVを取り込み、入庫情報を更新します。</p>
          <p>定期実行時間：毎日 18:00</p>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={batchExecutionHandle}
                >
                  バッチ実行をリクエスト
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function UpdateNextDelivery() {
  const [nextDeliveryMonth, setNextDeliveryMonth] = useState(moment().add(0, 'months'))
  const type = 'updateNextDelivery'
  const batchExecutionHandle = useBatchExecution(type, {nextDeliveryMonth: moment(nextDeliveryMonth).subtract(1, 'months').format('YYYYMM')})

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">次回配送情報更新 (自動注文) </p>
          <p>次回配送情報を元に、自動注文を実行します。</p>
          <p>定期実行時間：毎月 5日の09:00</p>
          <p className="text-bold mb-1 text-sm">実行例：「次回配送対象月」を2023/03を選択した場合</p>
          <div className="panel text-sm" style={{margin: 0, padding: 15}}>
            次回配送月が「2023/03」に設定されている次回配送情報に対して注文が作成される<br />
          </div>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="mb-2">
                <div className="mb-1">次回配送対象月</div>
                <DatePicker.MonthPicker
                  allowClear={false}
                  format={MONTH_FORMAT}
                  placeholder="月を選択"
                  defaultValue={nextDeliveryMonth}
                  onChange={nextDeliveryMonth => setNextDeliveryMonth(nextDeliveryMonth)
                }/>
              </div>
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={batchExecutionHandle}
                >
                  バッチ実行をリクエスト
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

function NotifyNextDelivery() {
  const [nextDeliveryMonth, setNextDeliveryMonth] = useState(moment().add(0, 'months'))
  const type = 'notifyNextDelivery'
  const batchExecutionHandle = useBatchExecution(type, {nextDeliveryMonth: moment(nextDeliveryMonth).subtract(2, 'months').format('YYYYMM')})

  return (
    <Card>
      <Row gutter={40}>
        <Col span={16}>
          <p className="text-bold mb-2">次回配送事前通知</p>
          <p>次回配送情報を元に、次回配送事前通知を実行します。</p>
          <p>定期実行時間：毎月 15日の09:00</p>
          <p className="text-bold mb-1 text-sm">実行例：「次回配送対象月」を2023/03を選択した場合</p>
          <div className="panel text-sm" style={{margin: 0, padding: 15}}>
            次回配送月が「2023/03」に設定されている次回配送情報に対して事前通知メールが送信される。<br />
          </div>
        </Col>
        <Col span={8}>
          <Form layout="vertical">
            <div className="panel">
              <div className="mb-2">
                <div className="mb-1">次回配送対象月</div>
                <DatePicker.MonthPicker
                  allowClear={false}
                  format={MONTH_FORMAT}
                  placeholder="月を選択"
                  defaultValue={nextDeliveryMonth}
                  onChange={nextDeliveryMonth => setNextDeliveryMonth(nextDeliveryMonth)
                }/>
              </div>
              <div className="text-center">
                <Button
                  type="primary"
                  block
                  onClick={batchExecutionHandle}
                >
                  バッチ実行をリクエスト
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

export default BatchAdmin
