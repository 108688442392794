import { fileClient } from '../http'
import { RESPONSE_OK } from '../constants'
import download, { downloadExcel } from '../download'

export default {
  async property(year, month) {
    const url = `/api/download/products/${year}/${month}`
    const response = await fileClient.get(url)

    if (response.status === RESPONSE_OK) {
      const fileName = `stock_unif_${year}${month}.csv`
      download(response.data, fileName)
    }
  },

  warehousePayment(data) {
    // todo
  },

  async receiveIssue(warehouseName) {
    const url = `/api/download/receive-issue/template?name=` + warehouseName
    const response = await fileClient.get(url)

    if (response.status === RESPONSE_OK) {
      const fileName = `shukko_kakutei_${getCurrentDateTimeString()}_${warehouseName}.csv`
      download(response.data, fileName)
    }
  },

  async receiveWarehousing(warehouseName) {
    const url = `/api/download/receive-warehousing/template?name=` + warehouseName
    const response = await fileClient.get(url)

    if (response.status === RESPONSE_OK) {
      const fileName = `nyuko_kakutei_${getCurrentDateTimeString()}_${warehouseName}.csv`
      download(response.data, fileName)
    }
  },

  async deliveryPayment(year, month) {
    const url = `/api/download/deliverer/${year}/${month}`
    const response = await fileClient.get(url)

    if (response.status === RESPONSE_OK) {
      const fileName = `delivery_unif_${year}${month}.csv`
      download(response.data, fileName)
    }
  },

  /** 経理依頼用_請求明細ダウンロード */
  async BillingForAccounting(year, month) {
    const url = `/api/download/billing/${year}/${month}`
    const response = await fileClient.get(url)

    if (response.status === RESPONSE_OK) {
      const fileName = `uni_keiri_${year}${month}.csv`
      downloadExcel(response.data, fileName)
    }
  },

  /** 楽一用_請求明細ダウンロード */
  async BillingForRakuichi(year, month) {
    const url = `/api/download/rakuichi/${year}/${month}`
    const response = await fileClient.get(url)

    if (response.status === RESPONSE_OK) {
      const fileName = `uni_rakuichi_${year}${month}.csv`
      downloadExcel(response.data, fileName)
    }
  },
}

function getCurrentDateTimeString() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const hour = now.getHours().toString().padStart(2, '0');
  const minute = now.getMinutes().toString().padStart(2, '0');
  const second = now.getSeconds().toString().padStart(2, '0');
  return `${year}${month}${day}${hour}${minute}${second}`;
}