import httpClient from '../http'

export default {
  search(params) {
    return httpClient.get(`/api/products`, { params })
  },

  show(id, params) {
    return httpClient.get(`/api/products/${id}`, { params })
  },

  showDetail(pNo) {
    return httpClient.get(`/api/product-details/${pNo}`)
  },

  setContents(pNo) {
    return httpClient.get(`/api/product-details/${pNo}/sets`)
  },
}
