import React, {useEffect, useState} from 'react'
import { Descriptions, Spin } from 'antd'
import Page from '../components/Page'
import contactApi from '../api/contact'
import { RESPONSE_OK } from '../constants'
import { sanitizeText } from '../sanitize'

function Contact({companyId}) {
  const [contact, setContact] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      if (companyId) return () => {}
      setLoading(true)
      const response = await contactApi.show(companyId)
      if (response.status === RESPONSE_OK) {
        setContact(response.data)
      }
      setLoading(false)
    })()
  }, [companyId])

  return (
    <Page title="お問い合わせ">
      <p className="mb-4">以下のお問い合わせ先へメールにてご連絡をお願いします。</p>
      <Spin spinning={loading}>
       {contact && (
        <>
          <Descriptions bordered layout="horizontal" column={1}>
            <Descriptions.Item label="お問合せ先">
              <span className="text-header">{contact.mailAddress}</span>
            </Descriptions.Item>
            <Descriptions.Item label="担当者">
              <span className="text-header">{contact.destination}</span>
            </Descriptions.Item>
          </Descriptions>

          <p className="mt-3"
            dangerouslySetInnerHTML={
              { __html: contact ? sanitizeText(contact.message) : '' }
            }
          ></p>
        </>
        )}
      </Spin>
    </Page>
  )
}

export default Contact
