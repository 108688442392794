import httpClient from '../http'

export function products(orderId) {
  return httpClient.get(`/api/returns/${orderId}/products`)
}

export function accept(data) {
  return httpClient.post(`/api/returns/accept`, data)
}

export default {
  getReturnInfo(orderId) {
    return httpClient.get(`/api/returns/${orderId}`)
  },

  sendReminderMail(orderId) {
    return httpClient.get(`/api/returns/${orderId}/reminder`)
  }
}
