import { useEffect, useState } from 'react'
import forumsApi from '../api/forum'

export default function useForumTypeSelect() {
  const [forumTypes, setForumTypes] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const response = await forumsApi.getType()
      setForumTypes(response.data)
    }
    fetch()
  }, [])

  return forumTypes
}
