import React from 'react'
import AppLink from '../../../components/AppLink'

export default function Thanks({ orderId }) {
  return (
    <>
      <div className="text-lg text-center mb-2">
        交換申し込みが完了しました。
      </div>
      <div className="text-center mb-4">
        注文番号：{orderId}
      </div>
      <div className="text-center">
        <AppLink to={`/orders/${orderId}/exchange`}>交換申し込みの詳細ページ</AppLink>
        から、返却先の宛先の確認と、返送用納品書を印刷して商品に同梱し配送手続きを行ってください。
      </div>
    </>
  )
}
