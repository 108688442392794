import httpClient from '../http'

export default {
  index(params) {
    return httpClient.get(`/api/accounts/`, { params })
  },

  companies() {
    return httpClient.get(`/api/companies`)
  },

  company(id) {
    return httpClient.get(`/api/companies/${id}`)
  },

  shippingMessage() {
    return httpClient.get(`/api/companies/shipping-message`)
  },

  stores(companyId) {
    return httpClient.get(`/api/companies/${companyId}/stores`)
  },

  show(id) {
    return httpClient.get(`/api/accounts/${id}`)
  },

  create(data) {
    return httpClient.post(`/api/accounts`, data)
  },

  passwordChange(data) {
    return httpClient.post(`/api/accounts/password-change`, data)
  },

  passwordChangeResetToken(data) {
    return httpClient.post(`/api/accounts/password-change-reset-token`, data)
  },

  passwordChangeSendMail(data) {
    return httpClient.post(`/api/accounts/password-change/send-mail`, data)
  },

  passwordChangeCheckToken(params) {
    return httpClient.get(`/api/accounts/password-change/check-token`, { params })
  },

  mailAddressChange(data) {
    return httpClient.post(`/api/accounts/mail-address-change`, data)
  }
}
