import { numFormat } from '../formatter'
import { PRODUCT_IMAGE_TYPE_SIZE } from '../constants'

const SEX_LABEL_MENS = 'MENS'
const SEX_LABEL_WOMENS = 'WOMENS'
const SEX_LABEL_UNISEX = 'UNISEX'
const TYPE_LABEL_NEW = '新品'
const TYPE_LABEL_STOCKED = '中古'

const numSorter = (a, b) => a - b

export default class Product {
  constructor({ productId, description, title, unitName, details = [], images = [] }) {
    this.productId = productId
    this.description = description
    this.title = title
    this.images = []
    this.sizeImage = ''
    this.unitName = unitName

    this.setImages(images)
    this.setDetails(details)
  }

  setImages(images) {
    images
      .sort((a, b) => {
        const aName = a.imageName.toUpperCase()
        const bName = b.imageName.toUpperCase()
        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })
      .forEach(({ imageType, imageName }) => {
        if (imageType === PRODUCT_IMAGE_TYPE_SIZE) {
          this.sizeImage = imageName
        } else {
          this.images.push(imageName)
        }
      })
  }

  setDetails(details) {
    this.details = Array.isArray(details)
      ? details.map(d => new ProductDetail(d))
      : new ProductDetail(details)
  }

  get sexLabel() {
    const sexNames = this.details.map(detail => detail.sexName)
    return [...new Set(sexNames)].sort(sexSorter).join(' / ')
  }

  get typeLabel() {
    const types = this.details.map(detail => detail.typeName)
    const typeSet = [...new Set(types)].sort(typeSorter)
    return typeSet.length > 1 ? typeSet.join(' / ') : `${typeSet[0]}のみ`
  }

  get taxInMinPrice() {
    const prices = this.details.map(detail => detail.taxInPrice * 1)
    return [...new Set(prices)].sort(numSorter).shift()
  }

  get taxInMaxPrice() {
    const prices = this.details.map(detail => detail.taxInPrice * 1)
    return [...new Set(prices)].sort(numSorter).pop()
  }

  get taxOutMinPrice() {
    const prices = this.details.map(detail => detail.taxOutPrice * 1)
    return [...new Set(prices)].sort(numSorter).shift()
  }

  get taxOutMaxPrice() {
    const prices = this.details.map(detail => detail.taxOutPrice * 1)
    return [...new Set(prices)].sort(numSorter).pop()
  }

  get taxInPriceLabel() {
    return this.taxInMaxPrice > this.taxInMinPrice
      ? `${numFormat(this.taxInMinPrice)} 円 〜 ${numFormat(this.taxInMaxPrice)} 円`
      : `${numFormat(this.taxInMinPrice)} 円`
  }

  get taxOutPriceLabel() {
    return this.taxOutMaxPrice > this.taxOutMinPrice
    ? `${numFormat(this.taxOutMinPrice)} 円 〜 ${numFormat(this.taxOutMaxPrice)} 円`
    : `${numFormat(this.taxOutMinPrice)} 円`
  }

  get mainImageUrl() {
    return this.images[0]
  }
}

export class ProductDetail {
  constructor({
    pNo,
    size,
    sexName,
    typeName,
    price,
    tax,
    stockCount,
    stockDisplayLimit,
    showStock,
    receivingExpectedDate,
    receivingExpectedCount,
    set,
    title,
    setTitle,
    returned,
    detailFreeMemo,
    beforeTitle,
    afterTitle
  }) {
    this.pNo = pNo
    this.size = size
    this.sexName = sexName
    this.typeName = typeName
    this.price = price
    this.tax = tax
    this._stockCount = stockCount
    this.stockDisplayLimit = stockDisplayLimit
    this.showStock = showStock
    this.receivingExpectedDate = receivingExpectedDate
    this.receivingExpectedCount = receivingExpectedCount
    this.set = set
    this.title = title
    this.setTitle = setTitle
    this.returned = returned
    this.detailFreeMemo = detailFreeMemo
    this.beforeTitle = beforeTitle
    this.afterTitle = afterTitle
  }

  get taxInPrice() {
    return this.price + this.tax
  }

  get taxOutPrice() {
    return this.price
  }

  get stockCount() {
    return this._stockCount > 0 ? this._stockCount : 0
  }

  get sexColorClassName() {
    switch (this.sexName) {
      case SEX_LABEL_MENS:
        return 'text-blue'
      case SEX_LABEL_WOMENS:
        return 'text-magenta'
      case SEX_LABEL_UNISEX:
        return 'text-purple'
      default:
        return ''
    }
  }
}

export class ProductSet {
  constructor({
    pNo,
    setPNo,
    setCount,
    size,
    title
  }) {
    this.pNo = pNo
    this.setpNo = setPNo
    this.setCount = setCount
    this.size = size
    this.title = title
  }
}

function sexSorter(a, b) {
  const order = {
    [SEX_LABEL_MENS]: 1,
    [SEX_LABEL_WOMENS]: 2,
    [SEX_LABEL_UNISEX]: 3,
  }

  return order[a] - order[b]
}

function typeSorter(a, b) {
  const order = {
    [TYPE_LABEL_NEW]: 1,
    [TYPE_LABEL_STOCKED]: 2,
  }

  return order[a] - order[b]
}
