import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Divider, Row, Col, Button, Spin, Form, Input, Icon } from 'antd'
import Page from '../components/Page'
import AppLink from '../components/AppLink'
import address from '../address'
import { useCurrentUser, useIsAdmin, useIsBuyer } from '../hooks/auth'
import { logout as logoutAction } from '../store/auth'
import accountsApi from '../api/accounts'
import { invalidPasswordReason, mailAddressRegEx } from '../validator'
import { RESPONSE_OK } from '../constants'
import { notifySuccess, notifyError } from '../notify'

const MY_ACCOUNT = 'マイアカウント'
const PASSWORD_CHANGE = 'パスワード変更'
const MAIL_ADDRESS_CHANGE = 'メールアドレス変更'

function Account() {
  const user = useCurrentUser()
  const [scene, setScene] = useState(MY_ACCOUNT)
  const isCurrentScene = (element) => element === scene
  return (
    <div>
      {isCurrentScene(MY_ACCOUNT) &&
        <AccountScene
          scene={scene}
          setScene={setScene}
          user={user}
        />
      }
      {isCurrentScene(PASSWORD_CHANGE) &&
        <PasswordChangeScene
          scene={scene}
          setScene={setScene}
          email={user.mailAddress}
        />
      }
      {isCurrentScene(MAIL_ADDRESS_CHANGE) &&
        <MailAddressChangeScene
          setScene={setScene}
          email={user.mailAddress}
        />
      }
    </div>
  )
}

function AccountScene({scene, setScene, user}) {
  const isBuyer = useIsBuyer()
  const isAdmin = useIsAdmin()
  const place = isBuyer ? user.store : user.company

  return (
    <Page title={scene}>
      <h1 className="text-md text-bold mb-3">
        <span className="mr-1">{user.store.storeName}</span>
      </h1>
      <Row type="flex" justify="space-between">
        <div className="text-header mb-3" style={{paddingTop: 30}}>
          {user.userName && (
            <>
              {user.userName} 様
              <Divider type="vertical" />
            </>
          )}
          {user.mailAddress}
        </div>
        <div>
          <Button type="primary" onClick={() => setScene(MAIL_ADDRESS_CHANGE)} style={{ display: 'block', marginBottom: '8px' }}>
            メールアドレスを変更する
          </Button>
          <Button type="primary" onClick={() => setScene(PASSWORD_CHANGE)} style={{ display: 'block', marginBottom: '8px' }}>
            パスワードを変更する
          </Button>
        </div>
      </Row>
      <div className="mb-3">
        <div className="panel">
          <ul className="plain-list">
            <li>
              <Row>
                <Col span={6}>店舗住所</Col>
                <Col span={18}>
                  {address({
                    postalCode: place.postalCode,
                    prefecture: place.prefecture,
                    city: place.city,
                    houseNumber: place.houseNumber,
                    buildingName: place.buildingName,
                  })}
                </Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col span={6}>TEL</Col>
                <Col span={18}>{place.tel}</Col>
              </Row>
            </li>
            <li>
              <Row>
                <Col span={6}>FAX</Col>
                <Col span={18}>{place.fax}</Col>
              </Row>
            </li>
          </ul>
          {isBuyer && !user.isParentStore && (
            <>
              <Divider />
              <ul className="plain-list">
                <li>
                  <Row>
                    <Col span={6}>注文承認</Col>
                    <Col span={18}>
                      {user.store.isApproved ? 'あり' : 'なし'}
                    </Col>
                  </Row>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
      <p>
        ※アカウント情報の変更が必要な場合には、
        <AppLink to="/forums">お問い合わせ</AppLink>よりご連絡ください。
      </p>
    </Page>
  )
}

const initialData = {
  password: '',
  reconfirmPassword : '',
  mailAddress: '',
}

const toPostData = (password, email) => ({
  password: password,
  mailAddress: email
})

function PasswordChangeScene({scene, setScene, email}) {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [reconfirmPassword, setReconfirmPassword] = useState('')

  const invalidPassword = () => invalidPasswordReason(password).length > 0
  const invalidPasswordMessage = () =>
    password.length > 0 ?
      (invalidPassword() ? invalidPasswordReason(password) : '')
    : ''
  const invalidReconfirmPassword = () => password !== reconfirmPassword
  const invalidReconfirmPasswordMessage = () =>
    reconfirmPassword.length > 0 ?
      (invalidReconfirmPassword() ? '同じパスワードを入力する必要があります' : '')
    : ''

  const handleSubmit = async () => {
    setLoading(true)
    const response = await accountsApi.passwordChange(toPostData(password, email))
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: 'パスワードを変更しました。' })
      setScene(MY_ACCOUNT)
    } else {
      notifyError({ message : 'パスワードの変更に失敗しました。再度お試し下さい'})
    }
    setLoading(false)
  }

  const handleInput = data => {
    const patch = {}
    const initialKeys = Object.keys(initialData)
    Object.keys(data).forEach(key => {
      if (initialKeys.includes(key)) {
        patch[key] = data[key]
      }
    })
    if (data['password'] !== undefined) {
      setPassword(data['password'])
    }
    if (data['reconfirmPassword'] !== undefined) {
      setReconfirmPassword(data['reconfirmPassword'])
    }
  }

  return (
    <div className='password-change-mail-input'>
      <div className="main">
        <Spin spinning={loading}>
          <Row type="flex" justify="center">
            <Col span={18}>
              <h1 className="text-center text-bold text-md">パスワード変更</h1>
            </Col>
            <Col className="mb-3" style={{ padding: 8 }}>
              変更したいパスワードを入力して下さい
            </Col>
          </Row>
          <Form layout="vertical">
            <Form.Item label="メールアドレス">
              <div>{email}</div>
            </Form.Item>
            <Form.Item
              label="パスワード"
              help={invalidPasswordMessage()}
              validateStatus={invalidPasswordMessage().length > 0 ? 'error' : ''}
            >
              <Input.Password
                value={password}
                placeholder="新しいパスワードを入力してください"
                onChange={e => handleInput({ password: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="パスワード（確認用）"
              help={invalidReconfirmPasswordMessage()}
              validateStatus={(invalidReconfirmPasswordMessage().length > 0) ? 'error' : ''}
            >
              <Input.Password
                value={reconfirmPassword}
                placeholder="確認のため同じパスワードを入力してください"
                onChange={e => handleInput({ reconfirmPassword: e.target.value })}
              />
            </Form.Item>
            <Row style={{ paddingTop: 20 }} type="flex" align="middle" justify="space-between">
              <Button type="link" onClick={() => setScene(MY_ACCOUNT)} style={{marginLeft: -20}} >
                &lt; マイアカウント画面へ戻る
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={loading}
                disabled={invalidPassword() || invalidReconfirmPassword()}
                style={{minWidth: 120}}
              >
                変更する
              </Button>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  )
}

function MailAddressChangeScene({setScene, email}) {
  const [loading, setLoading] = useState(false)
  const [mailAddress, setMailAddress] = useState('')
  const [reconfirmMailAddress, setReconfirmMailAddress] = useState('')
  const dispatch = useDispatch()
  const logout = () => dispatch(logoutAction())
  const isValidEmail = useMemo(() => {
    return mailAddress.match(mailAddressRegEx)
  }, [mailAddress])

  const invalidReconfirmMailAddress = () => mailAddress !== reconfirmMailAddress
  const invalidReconfirmMailAddressMessage = () =>
    reconfirmMailAddress.length > 0 ?
      (invalidReconfirmMailAddress() ? '同じパスワードを入力する必要があります' : '')
    : ''

  const handleSubmit = async () => {
    setLoading(true)
    console.log(toPostData(mailAddress))
    const response = await accountsApi.mailAddressChange(toPostData('', mailAddress))
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: 'メールアドレスを変更しました。' })
      const _ = setTimeout(() => {
        logout()
      }, 500);
    } else {
      notifyError({ message : 'メールアドレスの変更に失敗しました。' + response.data.message})
    }
    setLoading(false)
  }

  const handleInput = data => {
    const patch = {}
    const initialKeys = Object.keys(initialData)
    Object.keys(data).forEach(key => {
      if (initialKeys.includes(key)) {
        patch[key] = data[key]
      }
    })
    if (data['mailAddress'] !== undefined) {
      setMailAddress(data['mailAddress'])
    }
    if (data['reconfirmMailAddress'] !== undefined) {
      setReconfirmMailAddress(data['reconfirmMailAddress'])
    }
  }

  return (
    <div className='mail-address-change-input'>
      <div className="main">
        <Spin spinning={loading}>
          <Row type="flex" justify="center">
            <Col span={18}>
              <h1 className="text-center text-bold text-md">メールアドレス変更</h1>
            </Col>
            <Col className="mb-3" style={{ padding: 8 }}>
              変更先のメールをアドレスを入力して下さい
            </Col>
          </Row>
          <Form layout="vertical">
            <Form.Item label="変更前メールアドレス" style={{padding: 5}}>
              <div>{email}</div>
            </Form.Item>
            <Form.Item
              style={{paddingTop: 10}}
              label="変更後メールアドレス"
              help={(isValidEmail || mailAddress.length < 1) ? "" : <div>
                <div> 適切なメールアドレスを入力してください</div>
              </div>}
              validateStatus={(isValidEmail || mailAddress.length < 1) ? '' : 'error'}
            >
              <Input
                value={mailAddress}
                placeholder="変更先のメールアドレスを入力して下さい"
                onChange={e => handleInput({ mailAddress: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="変更後メールアドレス（確認用）"
              help={invalidReconfirmMailAddressMessage()}
              validateStatus={(invalidReconfirmMailAddressMessage().length > 0) ? 'error' : ''}
            >
              <Input
                value={reconfirmMailAddress}
                placeholder="確認のため同じメールアドレスを入力してください"
                onChange={e => handleInput({ reconfirmMailAddress: e.target.value })}
              />
            </Form.Item>
            <Col className="text-sm text-red" style={{}}>
              ※変更後は自動的にログアウトされ、旧メールアドレスではログインできなくなります。<br/>
              ※変更完了メールは新しいメールアドレス宛にのみ送信されます。
            </Col>
            <Row style={{ paddingTop: 20 }} type="flex" align="middle" justify="space-between">
              <Button type="link" onClick={() => setScene(MY_ACCOUNT)} style={{marginLeft: -20}} >
                &lt; マイアカウント画面へ戻る
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={loading}
                disabled={!isValidEmail || invalidReconfirmMailAddress()}
                style={{minWidth: 120}}
              >
                変更する
              </Button>
            </Row>
          </Form>
        </Spin>
      </div>
    </div>
  )
}

export default Account
