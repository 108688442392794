import { useEffect, useRef, useState } from 'react'
import productApi from '../../../api/product'
import {
  summaryProduct as productRentalStatusApi,
  exchangeFromCounts as exchangeFromCountsApi,
} from '../../../api/rental-status'

export default function useProduct({ accountId, item }) {
  const initialValue = (() => {
    const val = { ...item, status: null }
    return val.isSet ? { ...val, setContents: [] } : val
  })()

  const [product, setProduct] = useState(initialValue)
  const statusEl = useRef(null)
  const [statusLoading, setStatusLoading] = useState(false)

  const fetchSetContents = async () => {
    const response = await productApi.setContents(product.pNo)
    setProduct({ ...product, setContents: response.data })
  }

  const fetchStatus = async () => {
    const [ status, exchangeFromCount ] = await Promise.all([
      productRentalStatusApi(accountId, item.pNo),
      exchangeFromCountsApi(accountId, item.pNo)
    ])

    setProduct({
      ...product,
      status: status.data,
      exchangeFromCount: exchangeFromCount.data
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setStatusLoading(true)
          fetchStatus().then(() => setStatusLoading(false))
          observer.disconnect()
        }
      })
    }, { threshold: 0 })

    observer.observe(statusEl.current)

    return () => observer.disconnect()
  }, [item])

  return { product, statusEl, statusLoading, fetchSetContents }
}
