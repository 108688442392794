import React from 'react'
import { Table, Select, Checkbox, DatePicker } from 'antd'
import moment from 'moment'
import { extractAfterItem, extractDifferenceFromAfter, updateNextDeliveryItems } from '../util/compute'
import { MONTH_FORMAT } from '../../../constants'

// 次回配送内容変更入力テーブル
const NextDeliveryChangeInputTable = ({ dataSource, nextDeliveryChangeItems, setNextDeliveryChangeItems }) => {
  return (
    <Table bordered pagination={false} dataSource={dataSource} size="small" rowKey={record => record.itemStatusId}>
      <Column title="商品名" dataIndex="nextDeliveryItemTitle" key="nextDeliveryItemTitle" width='30%' render={renderRow} />
      <Column title="サイズ" key="nextDeliveryItemSize" width='15%' align="center" render={(_, record, itemIndex) => {
        return record.productDetailList.length < 2 ?
        renderRowCenter(record.nextDeliveryItemSize) :
        renderRowCenter(
          <Select showSearch optionFilterProp="children" defaultValue={record.nextDeliveryItemSize} style={listStyle} onChange={(value, option) => {
            const updatedItems = updateNextDeliveryItems({
              items: nextDeliveryChangeItems,
              index: itemIndex,
              record: record,
              changeProps: {nextDeliveryItemSize: value, nextDeliveryItemPNo: option.key}
            })
            setNextDeliveryChangeItems(updatedItems)
          }}>
            {record.productDetailList.map(item =>
              <Select.Option key={item.pNo} value={item.size}>{item.size}</Select.Option>
            )}
          </Select>
        )
      }} />
      <Column title="数量" key="nextDeliveryItemCount" width='10%' align="center" render={(_, record, itemIndex) => renderRowCenter(
        <Select showSearch optionFilterProp="children" defaultValue={record.nextDeliveryItemCount} style={listStyle} onChange={(value) => {
          const updatedItems = updateNextDeliveryItems({
            items: nextDeliveryChangeItems,
            index: itemIndex,
            record: record,
            changeProps: {nextDeliveryItemCount: value}
          })
          setNextDeliveryChangeItems(updatedItems)
        }}>
          {[...Array(100)].map((_, index) => <Select.Option key={index + 1} value={index + 1}>{index + 1}</Select.Option>)}
        </Select>
      )} />
      <Column title="次回発送月" key="nextDeliveryMonth" width='15%' align="center" render={(_, record, itemIndex) =>  {
        const afterItem = extractAfterItem({items: nextDeliveryChangeItems, index: itemIndex, beforeItem: record})
        const nextDeliveryMonth = moment(afterItem.nextDeliveryMonth, "YYYY/MM")
        return renderRowCenter(
          afterItem.isSuspendedDelivery ?
            "停止済み" :
            nextDeliveryMonth.format('YYYY/MM')
            /****
             * 次回配送月を変更可能にする場合
            <DatePicker.MonthPicker
              allowClear={false}
              format={MONTH_FORMAT}
              placeholder="月を選択"
              defaultValue={nextDeliveryMonth}
              disabledDate={current => { // current = 表示カレンダーの月の月初のDate
                const operableMonth = moment() < moment().add(0, 'months').startOf('month').add(15, 'days') ? 0 : 1
                // 現在時刻が当月の16日よりも前ならば今月以前が非活性、当月の16日よりも後ならば来月以前が非活性
                return current && current < moment().add(operableMonth, 'months').startOf('month').add(1, 'second')
              }}
              onChange={
                nextDeliveryMonth => {
                  const updatedItems = updateNextDeliveryItems({
                    items: nextDeliveryChangeItems,
                    index: itemIndex,
                    record: record,
                    changeProps: {nextDeliveryMonth: nextDeliveryMonth.format('YYYY/MM')}
                  })
                  setNextDeliveryChangeItems(updatedItems)
                }
            }/>
            ****/
        )
      }} />
      <Column title="次回発送" key="nextDeliverySelection" width='15%' align="center" render={(_, record, itemIndex) => {
        const [isDifference, beforeValue] = extractDifferenceFromAfter({
          items: nextDeliveryChangeItems,
          index: itemIndex,
          afterItem: record,
          key: "isSuspendedDelivery"
        })
        return renderRowCenter(
          <Checkbox defaultChecked={isDifference} onChange={event => {
            const updatedItems = updateNextDeliveryItems({
              items: nextDeliveryChangeItems,
              index: itemIndex,
              record: record,
              changeProps: {isSuspendedDelivery: beforeValue ? !event.target.checked: event.target.checked}
            })
            setNextDeliveryChangeItems(updatedItems)
          }}>{ beforeValue ? '再開する' : '停止する' }</Checkbox>
        )
      }} />
      <Column title="変更可能期限" dataIndex="closingDate" key="closingDate" width='15%' align="center" render={renderRow} />
    </Table>
  )
}
const { Column } = Table
const renderRow = (element) => (<span><div className="text-paragraph">{element}</div></span>)
const renderRowCenter = (element) => (<span><div style={{textAlign: "center"}} className="text-paragraph">{element}</div></span>)
const listStyle = { width: '100%' }

export default NextDeliveryChangeInputTable