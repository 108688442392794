import React,{ useMemo, useState } from 'react'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Spin
} from 'antd'
import Page from '../components/Page'
import {
  RESPONSE_OK, 
  ROLE_BUYER, 
  ROLE_SUBTENANT, 
  ROLE_SELLER, 
  RESPONSE_INVALID, 
  ROLE_NAME_BUYER,
  ROLE_NAME_SUBTENANT,
  ROLE_NAME_SELLER
} from '../constants'
import useCompanyStoreSelect from '../hooks/company-store-select'
import { useIsChildStore } from '../hooks/auth'
import { notifySuccess, notifyError } from '../notify'
import accountsApi from '../api/accounts'

const { Option } = Select

const PAGE_INPUT = 'input'
const PAGE_CONFIRM = 'confirm'

const initialData = {
  mailAddress : '',
  companyId: null,
  companyName: '',
  storeId: null,
  storeName: '',
  userName: '',
  password: '',
  roleId: ROLE_BUYER,
  roleName: ROLE_NAME_BUYER
}

const toPostData = accounts => ({
  mailAddress: accounts.mailAddress,
  companyId: accounts.companyId,
  storeId: accounts.storeId,
  userName: accounts.userName,
  password: accounts.password,
  roleId: accounts.roleId
})

const formItemStyle = {
  marginBottom: 0,
  paddingBottom: 0
}

function AccountCreate() {
  const [accounts, setAccounts] = useState(initialData)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(PAGE_INPUT)

  const handleConfirm = () => setPage(PAGE_CONFIRM)
  const handleBack = () => setPage(PAGE_INPUT)

  const handleInput = data => {
    const patch = {}
    const initialKeys = Object.keys(initialData)
    Object.keys(data).forEach(key => {
      if (initialKeys.includes(key)) {
        patch[key] = data[key]
      }
    })
    setAccounts(Object.assign({}, accounts, patch))
  }

  const handleSubmit = async () => {
    setLoading(true)
    const response = await accountsApi.create(toPostData(accounts))
    setLoading(false)
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: 'アカウントを登録しました。' })
      setAccounts(initialData)
      setPage(PAGE_INPUT)
    } else if (response.status === RESPONSE_INVALID) {
      notifyError({ message : 'メールアドレスが重複しているか、パスワードが不正です。'})
      setPage(PAGE_INPUT)
    }
  }

  return (
    <Page title="アカウントを登録する">
      {page === PAGE_INPUT && (
        <Spin spinning={loading}>
          <InputPage
            loading={loading}
            accounts={accounts}
            onInput={handleInput}
            onConfirm={handleConfirm}
          />
        </Spin>
      )}
      {page === PAGE_CONFIRM && (
        <ConfirmPage
          loading={loading}
          accounts={accounts}
          onSubmit={handleSubmit}
          onBack={handleBack}
        />
      )}
    </Page>
  )
}

function InputPage({ accounts, onInput, onConfirm }) {
  const isChildStore = useIsChildStore()
  const {
    companies, stores, companyId, setCompanyId, storeId, setStoreId
  } = useCompanyStoreSelect({ isChildStore })

  const roleNames = [
    { id:ROLE_SELLER, name:ROLE_NAME_SELLER },
    { id:ROLE_SUBTENANT, name:ROLE_NAME_SUBTENANT },
    { id:ROLE_BUYER, name:ROLE_NAME_BUYER },
  ]

  const getRoleName = id => {
    const role = roleNames.find(item => item.id === id)
    return role ? role.name : ''
  }

  const isDisabled = useMemo(() => {
    const validation = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,}$/i
    return Object.keys(accounts).some(key => (
      (key !== 'userName' && !accounts[key]) 
        || !validation.test(accounts.password)
        )
    )
  }, [accounts])

  return (
    <Form layout="horizontal" colon={false} labelAlign="left">
      <div className="mb-2">
        <Row gutter={24} type="flex" align="middle">
          {!isChildStore && (
            <>
              <Col span={4}>
                <Form.Item label="ロール" style={formItemStyle}>
                  <Select
                    allowClear
                    value={accounts.roleId}
                    onChange={val => {
                      onInput({ roleId: val, roleName: getRoleName(val) })
                    }}
                  >
                    {roleNames.map((item) => (
                      <Option key = {item.id} value = {item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="企業名" style={formItemStyle}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    value={companyId}
                    onChange={val => {
                      const item = companies.find(c => c.companyId === val)
                      onInput(item || { companyId: null, companyName: '' })
                      setCompanyId(val)
                    }}
                  >
                    {companies.map(item => (
                      <Option key={item.companyId} value={item.companyId}>
                        {item.companyName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label="店舗名" style={formItemStyle}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    allowClear
                    value={storeId}
                    onChange={val => {
                      const item = stores.find(s => s.storeId === val)
                      onInput(item || { storeId: null, storeName: '' })
                      setStoreId(val)
                    }}
                  >
                    {stores.map(item => (
                      <Option key={item.storeId} value={item.storeId}>
                        {item.storeName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </div>
      <div className="mb-2">
        <Row gutter={24} type="flex" align="middle">
          <Col span={12}>
            <Form.Item label="メールアドレス" style={formItemStyle}>
              <Input
                value={accounts.mailAddress}
                onChange={e => onInput({ mailAddress: e.target.value })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="パスワード" style={formItemStyle}>
              <Input
                value={accounts.password}
                placeholder="半角英数字の組み合わせかつ8文字以上で入力してください。"
                onChange={e => onInput({ password: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="mb-2">
        <Row gutter={24} type="flex" align="middle">
          <Col span={12}>
            <Form.Item label="ユーザー名" style={formItemStyle}>
              <Input
                value={accounts.userName}
                onChange={e => onInput({ userName: e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div className="mt-1 text-right">
          <Button
            type="primary"
            htmlType="submit"
            onClick={onConfirm}
            disabled={isDisabled}
          >
            確認する
          </Button>
        </div>
    </Form>
  )
}

function ConfirmPage({ accounts, loading, onSubmit, onBack }) {

  return (
    <div>
      <div className="panel mb-2">
        <ul className="plain-list">
          <li>
            <Row>
              <Col span={6}>企業名</Col>
              <Col span={18}>{accounts.companyName}</Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col span={6}>店舗名</Col>
              <Col span={18}>{accounts.storeName}</Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col span={6}>ロール</Col>
              <Col span={18}>{accounts.roleName}</Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col span={6}>メールアドレス</Col>
              <Col span={18}>{accounts.mailAddress}</Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col span={6}>ユーザー名</Col>
              <Col span={18}>{accounts.userName}</Col>
            </Row>
          </li>
          <li>
            <Row>
              <Col span={6}>パスワード</Col>
              <Col span={18}>{accounts.password}</Col>
            </Row>
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Button onClick={onBack}>もどる</Button>
          </Col>
          <Col>
            <Button type="primary" onClick={onSubmit} loading={loading}>
              アカウントを登録する
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default AccountCreate