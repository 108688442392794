import { Collapse, Button, Card, Col, Row, Typography } from 'antd'
import React from 'react'
import AppLink from '../components/AppLink'
import orderApi from '../api/order'

const { Panel } = Collapse;

const containerStyle = { backgroundColor: '#fff' }
const highlightedStyle = { borderColor: '#ffa39e' }

export default function ReturnCard({ id, highlighted = true }) {
  const handleDownloadOk = e => {
    e.stopPropagation()
    orderApi.outputReturnNote(id)
  }

  return (
    <Collapse style={
      highlighted ? { ...containerStyle, ...highlightedStyle } : containerStyle
    }>
      <Panel
        key={1}
        header={
          <Row type="flex" align="middle" justify="space-between">
            <Col>
              <span>返送について</span>
            </Col>
            <Col>
              <Button icon="file-pdf" onClick={handleDownloadOk}>
                返送用納品書を出力する
              </Button>
            </Col>
          </Row>
        }
        style={highlighted ? highlightedStyle : null}
      >
        <Card bordered={false} size="small">
          <p className="text-bold mb-1">返送時の宛先</p>
          <div className="mb-3">
            <ReturnAddress />
          </div>
          <p className="text-bold mb-1">返送の送料に関して</p>
          <p className="mb-3">
            <Typography.Text type="danger">「元払い（お客様負担）」</Typography.Text>
            にてお願いいたします。
          </p>
          <p className="text-bold mb-1">返送ガイドラインについて</p>
          <p>
            <AppLink to="/guides">
              <span>返送ガイドライン</span>
            </AppLink>
            を確認して梱包してください。また、返送用納品書を印刷して同梱してください。
          </p>
        </Card>
      </Panel>
    </Collapse>
  );
}

export function ReturnAddress() {
  return (
    <div className="panel">
      <ul className="plain-list">
        <li>
          <Row>
            <Col span={6}>宛名</Col>
            <Col span={18}>株式会社ユニフ UNI-STOCK 宛</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>宛先</Col>
            <Col span={18}>〒732-0068 広島市東区牛田新町4丁目4-8</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>電話番号</Col>
            <Col span={18}>082-516-1010</Col>
          </Row>
        </li>
      </ul>
    </div>
  )
}
