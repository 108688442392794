import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import {
  Form,
  Button,
  Row,
  Col,
  List,
  DatePicker,
  Icon,
  Spin,
  Divider
} from 'antd'
import Page from '../components/Page'
import LinkButton from '../components/LinkButton'
import Pagination from '../components/Pagination'
import SearchResult from '../components/SearchResult'
import { dateFormat, numFormat } from '../formatter'
import paymentApi from '../api/payment'
import {
  MONTH_FORMAT, RESPONSE_OK,
  PAYMENT_SUMMARY_TYPE_COMPANY, PAYMENT_SUMMARY_TYPE_STORE
} from '../constants'
import { usePaymentSummaryType } from '../hooks/auth'

const { MonthPicker } = DatePicker

const inputStyle = { width: '100%' }

function PaymentSearch() {
  const [payments, setPayments] = useState([])
  const [pageInfo, setPageInfo] = useState(null)
  const [term, setTerm] = useState(moment())
  const termText = useMemo(() => term ? term.format('YYYY-MM') : '', [term])
  const year = useMemo(() => termText.split('-')[0], [termText])
  const month = useMemo(() => termText.split('-')[1], [termText])
  const [query, setQuery] = useState({ term: termText, page: 1 })
  const [loading, setLoading] = useState(false)
  const { isPaymentSummaryTypeWith } = usePaymentSummaryType()

  useEffect(() => {
    (async () => {
      // 年月が指定されないと検索できない
      if (query.term === '') return () => {}
      setLoading(true)
      const response = await paymentApi.index(query)
      if (response.status === RESPONSE_OK) {
        const { payments: paymentList, ...rest } = response.data
        setPayments(paymentList)
        setPageInfo(rest)
      }
      setLoading(false)
    })()
  }, [query])

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    setQuery(Object.assign({}, query, { term: termText, page: 1 }))
  }

  // CSVファイル出力
  const downloadCsv = async () => {
    await paymentApi.csv({ term: termText })
  }

  return (
    <Page title="支払情報一覧">
      <div className="mb-3">
        <Form
          layout="vertical"
          colon={false}
          labelAlign="left"
          onSubmit={handleSearch}
        >
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={6}>
                <Form.Item label="対象月">
                  <MonthPicker
                    style={inputStyle}
                    format={MONTH_FORMAT}
                    placeholder="月を選択"
                    value={term}
                    onChange={term => setTerm(term)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="mt-3 text-center">
            <Button htmlType="submit" type="primary" disabled={!term}>
              検索
            </Button>
            <Button htmlType="button" className="ml-1" onClick={downloadCsv}>
              CSVファイル出力
            </Button>
          </div>
        </Form>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="お支払い情報が見つかりませんでした。条件を変更してもう一度検索してください。"
          >
            <List
              itemLayout="horizontal"
              dataSource={payments}
              renderItem={item => renderItem(item, year, month, isPaymentSummaryTypeWith)}
            />
          </SearchResult>
        </Spin>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </Page>
  )
}

function renderItem(item, year, month, isPaymentSummaryTypeWith) {
  const confirmedAt = moment(item.paymentConfirmedAt)
  const isConfirmed = moment().isSameOrBefore(confirmedAt)

  return (
    <List.Item
      actions={[
        <span className="text-header">
          {numFormat(item.totalAmount + item.taxAmount)} 円
        </span>,
        <LinkButton
          to={isPaymentSummaryTypeWith(PAYMENT_SUMMARY_TYPE_COMPANY) ?
            `/payments/company/${item.companyId}/${year}/${month}`:
            `/payments/store/${item.companyId}/${item.storeId}/${year}/${month}`
          }
          type="primary" small ghost app
        >
          <span>詳細を見る</span>
          <Icon type="right" />
        </LinkButton>,
      ]}
    >
      <List.Item.Meta
        title={
          <div className="mt-1">
            {"請求先： " + item.companyName}
            {item.storeName &&
              <span>
                 <Divider type="vertical" />
                 {"支払者： " + item.storeName}
              </span>
            }
          </div>
        }
        description={isConfirmed ? (
          <span>対象期間：{dateFormat(item.termFrom)} ~ {dateFormat(item.termTo)}</span>
        ) : (
          <span>確定日：{dateFormat(item.paymentConfirmedAt)}</span>
        )}
      />
    </List.Item>
  )
}

export default PaymentSearch
