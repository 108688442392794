import { useEffect, useState } from 'react'
import fetchOrder from '../fetch-order'

export default function useFetchOrder({ id, setLoading }) {
  const [order, setOrder] = useState(null)
  const [products, setProducts] = useState([])
  const [shippingCost, setShippingCost] = useState(0)
  const [taxAmount, setTaxAmount] = useState(0)

  const refreshOrder = async (id) => {
    const result = await fetchOrder(id)

    if (result) {
      const { productList, shippingCost, taxAmount, orderInfo } = result
      setOrder(orderInfo)
      setProducts(productList)
      setShippingCost(shippingCost)
      setTaxAmount(taxAmount)
    }
  }

  useEffect(() => {
    if (!id) return () => {}
    (async () => {
      setLoading(true)
      await refreshOrder(id)
      setLoading(false)
    })()
  }, [id])

  const patchOrder = patch => setOrder(Object.assign({}, order, patch))
  

  return { order, products, shippingCost, taxAmount, patchOrder, refreshOrder }
}