import { useState } from 'react'

export default function useLoading() {
  const [loading, setLoading] = useState(false)

  const withLoading = async callback => {
    setLoading(true)
    const response = await callback()
    setLoading(false)
    return response
  }

  return [loading, withLoading]
}
