import httpClient, { fileClient }  from '../http'
import download from '../download'
import { RESPONSE_OK } from '../constants'

export default {
  fromTo() {
    return httpClient.get(`/api/sales/from-to`)
  },

  async fileDownloadWithSalesSortTemplate(params) {
    const response = await fileClient.get(`/api/sales/download/sort-template`, { params })
    if (response.status === RESPONSE_OK) {
      const fileName = `sales_sort_toId${params.toId}_fromId${params.salesFromCompanyId}.csv`
      download(response.data, fileName)
    }
  },

  fileUpload(file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('filetype', 'csv')
    return httpClient.post(`/api/sales/upload/sort-csv`, formData )
  }
}
