import httpClient from '../http'

export default {
  store(){
    return httpClient.get(`/api/stores`)
  },
  myCompanyStore(){
    return httpClient.get(`/api/stores/mycompany`)
  },
  groups(){
    return httpClient.get(`/api/stores/groups`)
  },
}
