import React from 'react'
import { Card, Icon } from 'antd'

function SaleFromToBox({ from, to }) {
  const Box = ({ title, content, icon }) => (
    <Card
      title={
        <>
          <Icon type={icon} className="text-primary mr-1" />
          <span>{title}</span>
        </>
      }
      size="small"
      headStyle={{ color: 'inherit' }}
    >
      <div className="text-header">{content}</div>
    </Card>
  )

  return (
    <>
      <Box icon="shop" title="販売元" content={from} />
      <div className="text-center mt-1 mb-1">
        <Icon type="arrow-down" />
      </div>
      <Box icon="shopping" title="購入者" content={to} />
    </>
  )
}

export default SaleFromToBox
