import React from 'react'
import GlobalMenu from './GlobalMenu'
import { Layout, Typography, Divider } from 'antd'
import AppLink from './AppLink'
import { useCurrentUser } from '../hooks/auth'

const { Sider } = Layout
const { Text } = Typography

export const sidebarWidth = 220

const sidebarStyles = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  left: 0,
}

function Sidebar() {
  const user = useCurrentUser()

  if (!user) {
    return <Sider style={sidebarStyles} />
  }

  const accountName = (() => {
    // 管理者など、店舗の設定がない場合
    if (!user.store) {
      return user.company.companyName
    }
    // 店舗名を表示する
    return user.store.storeName
  })()

  return (
    <Sider style={sidebarStyles} width={sidebarWidth}>
      <SidebarLogo src={user.company.logoImage} />
      <SidebarDivider />
      <div className="m-2">
        <AppLink to={`/account`}>
          <Text style={{ color: '#fff' }}>{accountName} 様</Text>
        </AppLink>
      </div>
      <SidebarDivider />
      <GlobalMenu />
    </Sider>
  )
}

function SidebarDivider() {
  return <Divider style={{ margin: '1rem 0', opacity: 0.25 }} />
}

function SidebarLogo({ src }) {
  return (
    <figure>
      <img src={`${src}?t=${(new Date()).getTime()}`} alt="" style={{ width: '100%' }} />
    </figure>
  )
}

export default Sidebar
