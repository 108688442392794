import React, { useState } from 'react'
import { Button, Col, Divider, List, Row, Spin, Icon, } from 'antd'
import { useLocation } from '@reach/router'
import Page from '../../components/Page'
import Image from '../../components/Image'
import OrderType from '../../components/OrderType'
import AppLink from '../../components/AppLink'
import { SetContentsCollapse } from '../../components/SetContents'
import ApproveModal from '../TransferOrderShow/components/ApproveModal'
import Tag from '../../components/Tag'
import { numFormat } from '../../formatter'
import queryString from "query-string"
import useFetchTransferOrder from './hooks/fetch-transfer-order'
import useApproveModal from '../TransferOrderShow/hooks/approve-modal'

function TransferShow({ id }) {
  const [loading, setLoading] = useState(false)
  const {
    order, products, patchOrder
  } = useFetchTransferOrder({ id, setLoading })

  const {
    approveModalVisible, handleApproveOk, showApproveModal, hideApproveModal
  } = useApproveModal({ id, setLoading, patchOrder })

  const location = useLocation()
  const to = "/transfer-orders?" + queryString.stringify(location.state, { skipEmptyString: true })

  const renderListItem = product => (
    <List.Item>
      <div style={{ width: `100%` }}>
        <OrderRow product={product} />
      </div>
    </List.Item>
  )

  return (
    <Page title="申請内容の詳細">
      <Spin spinning={loading}>
        <div className="text-left">
          <AppLink to={to} >
            <Icon type="left" className="mr-1"/>
            <span>移動申請一覧に戻る</span>
          </AppLink>
        </div>
        <Divider />
        {order && (
          <>
            <OrderHeader
              order={order}
              canApprove={order.canApprove}
              onApprove={showApproveModal}
            />
            <div className="mt-3 mb-3">
              <OrderInfoPanel order={order} />
            </div>
          </>
        )}

        {order && (
          <List
            bordered={false}
            dataSource={products}
            renderItem={renderListItem}
          />
        )}
      </Spin>

      {order && (
        <>
          <ApproveModal
            id={order.orderId}
            visible={approveModalVisible}
            onOk={handleApproveOk}
            onCancel={hideApproveModal}
          />
        </>
      )}
    </Page>
  )
}

function OrderHeader({ order, canApprove, onApprove }) {

  return (
    <Row type="flex" align="middle" justify="space-between">
      <Col>
        <OrderType type={5} name="店舗移動" />
        <Divider type="vertical" />
        <Tag type={order.status === 1 ? "danger" : "primary"}>
          {order.status === 1 ? "承認待ち" : "申請完了"}
        </Tag>
      </Col>
      <Col>
        {canApprove && (
          <Button type="primary" icon="check" onClick={onApprove}>
            申込を承認する
          </Button>
        )}
      </Col>
    </Row>
  )
}

function OrderInfoPanel({ order }) {
  return (
    <div className="panel">
      <ul className="plain-list">
        <li>
          <Row>
            <Col span={6}>申請番号</Col>
            <Col span={18}>{order.orderId}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>申請者</Col>
            <Col span={18}>{order.storeName} 様</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>申請日</Col>
            <Col span={18}>{order.orderedAt}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>移動元店舗</Col>
            <Col span={18}>{order.fromStoreName}</Col>
          </Row>
        </li>
        <li>
          <Row>
            <Col span={6}>移動先店舗</Col>
            <Col span={18}>{order.toStoreName}</Col>
          </Row>
        </li>
      </ul>
    </div>
  )
}

function OrderRow({ product }) {

  return (
    <>
      <Row gutter={16} type="flex" align="middle">
        <Col span={4}>
          <Image src={product.mainImageUrl} />
        </Col>
        <Col span={16}>
          <p className="mb-1">
            <AppLink to={`/products/${product.productId}`}>
              {product.title}
            </AppLink>
          </p>
          <div className="mb-1">
            {product.sexName}
            <Divider type="vertical" />
            {product.size}
            <Divider type="vertical" />
            {product.typeName}
          </div>
          <p>{product.pNo}</p>
        </Col>
        <Col span={4}>移動数：{numFormat(product.orderCount)}</Col>
      </Row>
      <div className="mt-1">
        {product.set && (
          <SetContentsCollapse item={product} title={product.setTitle}/>
        )}
      </div>
    </>
  )
}

export default TransferShow
