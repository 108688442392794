import React from 'react'
import { Modal } from 'antd'

// 注文キャンセルモーダル
export default function CancelModal({ id, visible, onOk, onCancel }) {
  return (
    <Modal
      title="注文のキャンセル"
      visible={visible}
      okText="キャンセルする"
      okType="danger"
      cancelText="戻る"
      onOk={onOk}
      onCancel={onCancel}
    >
      <p className="mb-2">注文番号：{id}</p>
      <p className="mb-2">
        キャンセルすると複数商品を注文していた場合でも、すべての注文が取り消しとなります。
      </p>
      <p>この注文をキャンセルしますか？</p>
    </Modal>
  )
}
