import { useState } from 'react'
import { NEXT_DELIVERY_CHANGE_INPUT } from '../util/constants'

const useScene = () => {
  const [scene, setScene] = useState(NEXT_DELIVERY_CHANGE_INPUT)
  const isCurrentScene = (element) => element === scene
  const pageTitle = () => scene
  return { scene, setScene, isCurrentScene, pageTitle }
}

export default useScene