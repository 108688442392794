import React from 'react'
import { useDispatch } from 'react-redux'
import { Layout, Divider, Button } from 'antd'
import { logout as logoutAction } from '../store/auth'
import LinkButton from './LinkButton'

const footerStyles = { padding: '1.5rem 1rem' }

function Footer() {
  const dispatch = useDispatch()
  const logout = () => dispatch(logoutAction())

  return (
    <Layout.Footer style={footerStyles}>
      <div className="container">
        <div className="text-center"></div>
      </div>
    </Layout.Footer>
  )
}

function FooterDivider() {
  return <Divider type="vertical" style={{ backgroundColor: `#d9d9d9` }} />
}

export default Footer
