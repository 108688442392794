import isEqual from 'lodash/isEqual'

const updateNextDeliveryItems = ({items, index, record, changeProps}) => {
  const staffIndex = items.findIndex(item => item.staffNo === record.staff.staffNo)
  const changeItem = items[staffIndex].afterItemStatuses[index]
  const updateItems = JSON.parse(JSON.stringify(items))
  updateItems[staffIndex].afterItemStatuses[index] = Object.assign({}, changeItem, changeProps)
  return updateItems
}

const extractDifferenceFromBefore = ({items, index, beforeItem, key}) => {
  const staffIndex = items.findIndex(item => item.staffNo === beforeItem.staff.staffNo)
  const afterItem = items[staffIndex].afterItemStatuses[index]
  return [beforeItem[key] !== afterItem[key], afterItem[key]]
}

const extractDifferenceFromAfter = ({items, index, afterItem, key}) => {
  const staffIndex = items.findIndex(item => item.staffNo === afterItem.staff.staffNo)
  const beforeItem = items[staffIndex].beforeItemStatuses[index]
  return [beforeItem[key] !== afterItem[key], beforeItem[key]]
}

const extractEditedItems = ({nextDeliveryChangeItems}) => (nextDeliveryChangeItems.filter(
  staffInfo => staffInfo.beforeItemStatuses.some((beforeItem, index) => !isEqual(beforeItem, staffInfo.afterItemStatuses[index])
)).map(staffInfo => {
  const beforeItems = staffInfo.beforeItemStatuses.filter((beforeItem, index) => !isEqual(beforeItem, staffInfo.afterItemStatuses[index]))
  const afterItems = staffInfo.afterItemStatuses.filter((afterItem, index) => !isEqual(afterItem, staffInfo.beforeItemStatuses[index]))
  return Object.assign({}, staffInfo, {beforeItemStatuses: beforeItems, afterItemStatuses: afterItems})
}))

const extractBeforeItem = ({items, index, afterItem}) => {
  const staffIndex = items.findIndex(item => item.staffNo === afterItem.staff.staffNo)
  return items[staffIndex].beforeItemStatuses[index]
}

const extractAfterItem = ({items, index, beforeItem}) => {
  const staffIndex = items.findIndex(item => item.staffNo === beforeItem.staff.staffNo)
  return items[staffIndex].afterItemStatuses[index]
}

export { updateNextDeliveryItems, extractDifferenceFromBefore, extractDifferenceFromAfter, extractEditedItems, extractBeforeItem, extractAfterItem }