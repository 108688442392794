import React from 'react'
import { Col, Pagination as AntPagination, Row, Typography } from 'antd'
import { PAGE_ITEMS } from '../constants'

function Pagination({
  currentPage,
  allCount,
  fromCount,
  toCount,
  onChange,
  pageSize = PAGE_ITEMS,
  disabled
}) {
  return (
    <Row type="flex" align="middle" justify="space-between">
      <Col>
        <AntPagination
          defaultCurrent={1}
          defaultPageSize={pageSize}
          current={currentPage}
          total={allCount}
          onChange={onChange}
          disabled={disabled}
        />
      </Col>
      <Col>
        {allCount > 0 && (
          <Typography.Text type="secondary">
            {fromCount} - {toCount}件（全{allCount}件）
          </Typography.Text>
        )}
      </Col>
    </Row>
  )
}

export default Pagination
