import React, { useEffect, useState } from 'react'
import { Form, Select, Button, Row, Col, Card, Drawer, Checkbox, Input, Spin } from 'antd'
import Page from '../components/Page'
import AppLink, { appNavigate } from '../components/AppLink'
import forumApi from '../api/forum'
import { RESPONSE_OK, RESPONSE_INVALID, RESPONSE_SERVER_ERROR } from '../constants'
import useStoreSelect from '../hooks/store-select'
import useForumTypeSelect from '../hooks/forum-type-select'
import Pagination from '../components/Pagination'
import { dateDetailFormat } from '../formatter'
import ForumTag from '../components/ForumTag'
import { notifySuccess, notifyError } from '../notify'
import { sanitizeText } from '../sanitize'

const initialQuery = { storeId: null, isGetComplete: false, page: 1 }

const { Option } = Select

const { TextArea } = Input

const toPostData = forum => ({
  forumType: forum.forumType,
  title: forum.title,
  contents: forum.contents,
  orderId: forum.orderId,
})

const clearForum = forum => ({
  forumType: null,
  title: null,
  contents: null,
  orderId: '',
})

const initialData = {
  forumType: null,
  title: null,
  contents: null,
  orderId: '',
}

function ForumSearch() {
  const [query, setQuery] = useState(initialQuery)
  const [pageInfo, setPageInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [forums, setForums] = useState([])
  const [forum, setForum] = useState(initialData)
  const [storeId, setStoreId] = useState(null)
  const forumTypes = useForumTypeSelect()
  const [isGetComplete, setIsGetComplete] = useState(false)
  const stores = useStoreSelect()

  useEffect(() => {
    (async () => {
      setLoading(true)
      fetchForumSearch()
      setLoading(false)
    })()
  }, [query])

  async function fetchForumSearch() {
    const response = await forumApi.search(query)
    if (response.status === RESPONSE_OK) {
      const { results, ...rest } = response.data
      setForums(results)
      setPageInfo(rest)
    }
  }

  const handlePaginate = page => {
    setQuery(Object.assign({}, query, { page }))
  }

  const handleSearch = e => {
    e.preventDefault()
    setQuery(Object.assign({}, query, {
      storeId,
      isGetComplete,
      page: 1,
    }))
  }

  const handleInput = data => {
    setForum(Object.assign({}, forum, data))
  }

  const handleSubmit = async () => {
    setLoading(true)
    const response = await forumApi.create(toPostData(forum))
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: 'お問い合わせを登録しました。' })
      setDrawerVisible(false)
      fetchForumSearch()
      setForum(clearForum(forum))
    } else if (response.status === RESPONSE_INVALID) {
      notifyError({ message: '必須項目が空か、文字数をオーバーしています。2000文字以下で入力してください。' })
    } 
    else if (response.status === RESPONSE_SERVER_ERROR) {
      notifyError({ message: '必須項目が空か、文字数をオーバーしています。2000文字以下で入力してください。' })
    }
    setLoading(false)
  }

  const [drawerVisible, setDrawerVisible] = useState(false)

  return (
    <Page title="お問い合わせ">
      <div className="text-right mb-2">
        <Button
          icon="message"
          onClick={() => setDrawerVisible(!drawerVisible)}
        >
          新規お問い合わせを作成する
        </Button>
        <Drawer
          closable={false}
          title="新規お問い合わせを作成する"
          visible={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          width={560}
          bodyStyle={{ paddingBottom: 92 }}
        >
          <Form colon={false} layout="vertical">
            <Form.Item label="お問い合わせ区分">
              <Select onChange={val => handleInput({ forumType: val })}>
                {forumTypes.map((type, index) => (
                  <Option value={index + 1} key={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="タイトル">
              <Input
                value={forum.title}
                onChange={e => handleInput({ title: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="お問い合わせ内容">
              <TextArea
                value={forum.contents}
                onChange={e => handleInput({ contents: e.target.value })}
                autoSize={{ minRows: 12 }}
              />
            </Form.Item>
            <Form.Item label="注文ID">
              <Input
                value={forum.orderId}
                onChange={e => handleInput({ orderId: e.target.value })}
              />
            </Form.Item>
            <div style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: 16,
              background: '#fff',
              textAlign: 'right',
            }}>
              <div className="text-right">
                <Button
                  className="mr-1"
                  onClick={() => setDrawerVisible(false)}
                >
                  閉じる
                </Button>
                <Button type="primary" loading={loading || !drawerVisible} onClick={handleSubmit}>作成する</Button>
              </div>
            </div>
          </Form>
        </Drawer>
      </div>

      <Form
        layout="horizontal"
        colon={false}
        labelAlign="left"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onSubmit={handleSearch}
      >
        <div className="panel">
          <Row gutter={24} type="flex" align="middle">
            <Col span={12}>
              <Form.Item label="店舗" style={{ marginBottom: 0 }}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  allowClear
                  onChange={val => setStoreId(val)}
                >
                  {stores.map((store) => (
                    <Option value={store.storeId} key={store.storeId}>
                      {store.storeName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={null} style={{ marginBottom: 0 }}>
                <Checkbox
                  onChange={event => setIsGetComplete(event.target.checked)}
                >
                  完了を含める
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className="mt-3 text-center">
          <Button htmlType="submit" type="primary">検索</Button>
        </div>
      </Form>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}

      <Spin spinning={loading}>
        <div className="mt-3 mb-3">
          {forums.map((item, index) => (
            <div key={item.id} className={index > 0 ? 'mt-3' : null}>
              {renderItem(item)}
            </div>
          ))}
        </div>
      </Spin>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </Page>
  )
}

function renderItem(item) {
  return (
    <Card>
      <div style={{
        padding: "0px 5px",
        position: "absolute",
        top: "-10px",
        left: "20px",
        background: "white",
        border: "1px solid grey",
        borderRadius: "2px"
      }}>No. {item.id}</div>
      <Row gutter={24}>
        <Col span={19}>
          <div className="mb-2">
            <ForumTag status={item.statusCode}>{item.statusName}</ForumTag>
            <span className="ml-2 mr-2">{item.accountName}</span>
            <span className="text-mute">{dateDetailFormat(item.createdAt) + ' 作成'}</span>
          </div>
        </Col>
        <Col span={5}>
          <div className="mb-2">
            <Button type="primary" style={{ marginTop: 0, marginRight :0}} onClick={() => {
              appNavigate(`/forums/${item.id}`)
            }}>返信／コメントの確認</Button>
          </div>
        </Col>
        <p className="mb-2 text-bold">
            <AppLink to={`/forums/${item.id}`}>{item.title}</AppLink>
          </p>
          <p className="text-paragraph" 
            style={{ overflowWrap: 'break-word' }}
            dangerouslySetInnerHTML={
              {__html: sanitizeText(item.contents)}
            }
          />
      </Row>
      
    </Card>
  )
}

export default ForumSearch
