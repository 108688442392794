import { useState } from 'react'
import orderApi from '../../../api/order'
import { RESPONSE_OK } from '../../../constants'
import { notifySuccess } from '../../../notify'

export default function useApproveModal({ id, setLoading, patchOrder }) {
  const [approveModalVisible, setApproveModalVisible] = useState(false)

  const handleApproveOk = async () => {
    setApproveModalVisible(false)
    setLoading(true)

    const response = await orderApi.approve(id)

    patchOrder({
      status: response.data.statusCode,
      statusName: response.data.statusName,
      orderedAt: response.data.createdAt,
    })

    setLoading(false)

    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: '注文を承認しました。' })
    }
  }

  const showApproveModal = () => setApproveModalVisible(true)
  const hideApproveModal = () => setApproveModalVisible(false)

  return {
    approveModalVisible,
    handleApproveOk,
    showApproveModal,
    hideApproveModal
  }
}
