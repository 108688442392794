import React, { useEffect, useState } from 'react'
import { Form, Button, List, Icon, Row, Col, Divider, Card, Drawer, Input } from 'antd'
import Page from '../components/Page'
import AppLink from '../components/AppLink'
import forumApi from '../api/forum'
import { RESPONSE_OK, RESPONSE_INVALID } from '../constants'
import ForumTag from '../components/ForumTag'
import { dateDetailFormat } from '../formatter'
import { FORUM_STATUS_COMPLETED } from '../constants'
import { notifySuccess, notifyError } from '../notify'
import { sanitizeText } from '../sanitize'

const toPostData = comment => ({
  comment: comment.comment
})

const clearComment = comment => ({
  comment: null
})

const initialData = {
  comment: null
}

function ForumShow({ id }) {
  const [forums, setForums] = useState([])
  const [loading, setLoading] = useState(false)
  const [comment, setComment] = useState(initialData)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const showDrawer = () => setDrawerVisible(true)
  const hideDrawer = () => setDrawerVisible(false)
  const isCompleted = forums.statusCode === FORUM_STATUS_COMPLETED

  useEffect(() => {
    (async () => {
      await fetchForumShow(id)
    })()
  }, [id])

  async function fetchForumShow(id) {
    const response = await forumApi.show(id)
    if (response.status === RESPONSE_OK) {
      setForums(response.data)
    }
  }

  const handleCompleted = async () => {
    setLoading(true)
    const response = await forumApi.complete(id)
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: 'お問い合わせを完了しました。' })
      fetchForumShow(id)
    } else if (response.status === RESPONSE_INVALID) {
      notifyError({ message: '編集権限がありません。' })
    }
    setLoading(false)
  }

  const handleInput = data => {
    setComment(Object.assign({}, comment, data))
  }

  const handleSubmit = async () => {
    setLoading(true)
    const response = await forumApi.comment(id, toPostData(comment))
    if (response.status === RESPONSE_OK) {
      notifySuccess({ message: 'コメントを登録しました。' })
      hideDrawer()
      fetchForumShow(id)
      setComment(clearComment(comment))
    } else if (response.status === RESPONSE_INVALID) {
      notifyError({ message: 'コメントが空か、文字数をオーバーしています。2000文字以下で入力してください。' })
    }
    setLoading(false)
  }

  return (
    <Page
      title="お問い合わせ"
      mainStyle={{ paddingBottom: drawerVisible ? 200 : 0 }}
    >
      <div className="mb-2">
        <div className="text-left">
          <AppLink to={`/forums`} >
            <Icon type="left" className="mr-1"/>
            <span>お問い合わせ一覧に戻る</span>
          </AppLink>
        </div>
        <Divider />
        <Row type="flex" align="middle" justify="space-between">
          <Col span={12}>
            <Icon type="message" className="text-md text-primary" />
            <span className="ml-1">{forums.forumType}</span>
            <Divider type="vertical" />
            <ForumTag status={forums.statusCode}>{forums.statusName}</ForumTag>
          </Col>
          {!isCompleted && forums.canEdit && (
            <Col span={12}>
              <div className="text-right">
                <Button type="primary" loading={loading} onClick={handleCompleted}>
                  お問い合わせをクローズする
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </div>
      <Card style={{ borderBlockColor: `#d9d9d9` }}>
        <div style={{
          padding: "0px 5px",
          position: "absolute",
          top: "-10px",
          left: "20px",
          background: "white",
          border: "1px solid grey",
          borderRadius: "2px"
        }}>No. {forums.id}</div>
        <div className="mb-2">
          <span className="mr-2">{forums.accountName}</span>
          {forums.orderId && (
            <div style={{
              display: 'inline-block',
              padding: '0 0.5rem',
              lineHeight: 2,
              fontSize: 12,
              border: '1px solid #d9d9d9',
              borderRadius: '99999px',
            }}>
              <span>注文番号</span>
              <Divider type="vertical" />
              <span>{forums.orderId}</span>
            </div>
          )}
        </div>
        <p className="text-header text-bold text-md mb-2">{forums.title}</p>
        {forums.contents && (
          <p
            className="mb-2 text-paragraph"
            dangerouslySetInnerHTML={
              {__html: sanitizeText(forums.contents)}
            }
            style={{ overflowWrap: 'break-word' }}
          />
        )}
        <p className="mt-3 text-mute">{dateDetailFormat(forums.createdAt) + ' 作成'}</p>
      </Card>

      {(!isCompleted && forums.canEdit) && (
        <div className="mt-3 text-center">
          <Button onClick={showDrawer} type="primary" ghost>コメントを書く</Button>
        </div>
      )}

      {forums.comment && (
        <div className="mt-3">
          <List
            size="large"
            itemLayout="vertical"
            bordered
            dataSource={forums.comment}
            renderItem={item => (
              <List.Item>
                <p className="mt-1 mb-2 text-header">{item.accountName}</p>
                <p
                  className="mb-2"
                  dangerouslySetInnerHTML={
                    {__html: sanitizeText(item.comment)}
                  }
                />
                <p className="mb-1 text-mute">{dateDetailFormat(item.createdAt) + ' 作成'}</p>
              </List.Item>
            )}
          />
        </div>
      )}

      {(forums.comment && !isCompleted && forums.canEdit) && (
        <div className="mt-3 text-center">
          <Button onClick={showDrawer} type="primary" ghost>コメントを書く</Button>
        </div>
      )}

      <Drawer
        mask={false}
        closable={false}
        placement="bottom"
        visible={drawerVisible}
        getContainer='main'
        style={{ left: 220, right: 0, width: `calc(100% - 220px)` }}
        bodyStyle={{ paddingBottom: 92 }}
        height={240}
      >
        <Form
          colon={false}
          layout="vertical"
          labelAlign="left"
        >
          <Form.Item label="コメント">
            <Input.TextArea
              autoSize={{ minRows: 4 }}
              value={comment.comment}
              onChange={e => handleInput({ comment: e.target.value })}
            />
          </Form.Item>
          <div style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: 16,
            background: '#fff',
            textAlign: 'right',
          }}>
            <div className="text-right">
              <Button onClick={hideDrawer} className="mr-1" htmlType="button">
                閉じる
            </Button>
              <Button type="primary" loading={loading || !drawerVisible} htmlType="submit" onClick={handleSubmit}>
                登録する
              </Button>
            </div>
          </div>
        </Form>
      </Drawer>
    </Page>
  )
}

export default ForumShow
