import axios from 'axios'
import store from './store'
import { setResponse } from './store/error'
import { RESPONSE_INVALID } from './constants'

// 通信成功時（特別なことはしない）
const onSuccess = response => response

// 通信失敗時
const onError = error => {
  const { status, data } = error.response

  // バリデーションエラー以外はストアに入れる
  if (status !== RESPONSE_INVALID) {
    store.dispatch(setResponse({ status, data }))
  }

  return error.response
}

// 通常のHTTPクライアント
const client = axios.create()
client.interceptors.response.use(onSuccess, onError)
export default client

// ファイルダウンロード用のクライアント
export const fileClient = axios.create({ responseType: 'blob' })
fileClient.interceptors.response.use(onSuccess, onError)
