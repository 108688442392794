const existsUnselectedItem = (selectedItems) => selectedItems.map(item => item.selectedStatusList).flat(1).filter(item => item === '').length > 0 

const transformFormItems = (selectedItems) => (selectedItems.map(usageStatusItem => {
  const {itemStatuses , ...staff} = Object.assign({}, usageStatusItem.staff, {})
  const statuses = usageStatusItem.staff.itemStatuses.filter(content =>
    usageStatusItem.selectedStatusList.includes(content.itemStatusId)
  ).map( item => Object.assign({}, item, {staff}))
  return Object.assign({}, staff, {beforeItemStatuses: statuses, afterItemStatuses: statuses})}).filter(item => item.beforeItemStatuses.length > 0)
)

const updatedSelectedUsageStatusItems = (currentItems, isInit) => (currentItems.map(item => ({
  staff: item,
  selectedStatusList: item.itemStatuses.map(
    content => (content.isExpiredChangeDeadline || !content.existsNextDelivery) ? null: isInit ? '': content.itemStatusId
  )
})))

export { transformFormItems, updatedSelectedUsageStatusItems, existsUnselectedItem }