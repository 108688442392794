import React, { useState } from 'react'
import { Button, Col, Divider, Form, Icon, Input, List, Row, Spin, Typography } from 'antd'
import { useLocation } from '@reach/router'
import Page from '../../components/Page'
import Pagination from '../../components/Pagination'
import Image from '../../components/Image'
import LinkButton from '../../components/LinkButton'
import SearchResult from '../../components/SearchResult'
import { ProductSetContentsCollapse } from '../../components/ProductSetContents'
import useAccount from './hooks/account'
import useSearchProducts from './hooks/search-products'
import useProduct from './hooks/product'
import AppLink from '../../components/AppLink'
import queryString from "query-string"

const { Text } = Typography

const formItemStyle = { marginBottom: 0, paddingBottom: 0 }

export default function RentalStatusShow({ accountId }) {
  const account = useAccount(accountId)
  const { search, pageInfo, products, loading } = useSearchProducts(accountId)
  const [productName, setProductName] = useState('')

  const handlePaginate = page => search({ page })

  const handleSearch = e => {
    e.preventDefault()
    search({ productName, page: 1 })
  }

  const location = useLocation()
  const to = "/rental-status?" + queryString.stringify(location.state, { skipEmptyString: true })
  const isReturn = location.state.companyId != null

  return (
    <Page title="レンタル状況">
      {account && (
        <Row gutter={24} type="flex" align="middle">
          <Col span={24}>
            {isReturn && 
              <>
                <div className="text-left">
                  <AppLink to={to} >
                    <Icon type="left" className="mr-1"/>
                    <span>店舗レンタル状況に戻る</span>
                  </AppLink>
                </div>
                <Divider />
              </>
            }
            <Row>
              <Col span={18}>
                <div className="text-bold mb-2 mt-1">
                  <span className="mr-2">{account.companyName}</span>
                  <span>{account.storeName}</span>
                </div>
              </Col>
              <Col span={6}>
                <div className="text-right mb-2">
                  <LinkButton
                    to={"/transfer-orders"}
                    type="default" app
                  >
                    <Icon type="container" />
                    <span>店舗移動申請履歴</span>
                  </LinkButton>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <div className="mb-3">
        <Form layout="vertical" onSubmit={handleSearch}>
          <div className="panel">
            <Row gutter={24} type="flex" align="middle">
              <Col span={24}>
                <Form.Item label="商品名" style={formItemStyle}>
                  <Input
                    value={productName}
                    onChange={e => setProductName(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="mt-3 text-center">
            <Button type="primary" htmlType="submit">
              検索する
            </Button>
          </div>
        </Form>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}

      <div className="mt-3 mb-3">
        <Spin spinning={loading}>
          <SearchResult
            pageInfo={pageInfo}
            zeroMessage="商品が見つかりませんでした。"
          >
            <List
              bordered={false}
              dataSource={products}
              renderItem={item => (
                <List.Item>
                  <div style={{ width: `100%` }}>
                    <Product accountId={accountId} item={item} />
                  </div>
                </List.Item>
              )}
            />
          </SearchResult>
        </Spin>
      </div>

      {pageInfo && (
        <Pagination
          currentPage={pageInfo.currentPage}
          allCount={pageInfo.allCount}
          fromCount={pageInfo.fromCount}
          toCount={pageInfo.toCount}
          onChange={handlePaginate}
        />
      )}
    </Page>
  )
}

function Product({ accountId, item }) {
  const {
    product, statusEl, statusLoading, fetchSetContents
  } = useProduct({ accountId, item })

  const hasRental = (() => {
    if (!product.status) return false
    if (!item.isSet) return product.status.rental > 0
    return product.exchangeFromCount.some(item => item.count > 0)
  })()

  return (
    <>
      <Row gutter={16} type="flex">
        <Col span={3}>
          <Image src={item.image ? item.image.imageName : null} />
        </Col>
        <Col span={12}>
          <p className="mb-1">
            <AppLink to={`/products/${item.productId}`}>
              {item.productTitle}
            </AppLink>
          </p>
          <div>
            {item.sexName}
            <Divider type="vertical" />
            {item.size}
            <Divider type="vertical" />
            {item.pNo}
          </div>
          {item.isSet && (
            <div className="mt-1">
              <ProductSetContentsCollapse
                items={product.setContents}
                header={product.title}
                onOpen={() => fetchSetContents(product)}
                loading={product.setContents.length === 0}
              />
            </div>
          )}
        </Col>
        <Col span={9}>
          <Spin spinning={statusLoading}>
            <div className="panel is-narrow" ref={statusEl}>
              <Row type="flex" align="middle">
                <Col span={16}>レンタル中</Col>
                <Col span={8} className="text-right">
                  <Text className="text-header">
                    {product.status ? product.status.rental : '-'} 点
                  </Text>
                </Col>
                <Col span={16}>未返却（点数）</Col>
                <Col span={8} className="text-right">
                  <Text className="text-header">
                    {product.status ? product.status.unreturned : '-'} 点
                  </Text>
                </Col>
                <Col span={16}>解約済み（当月）</Col>
                <Col span={8} className="text-right">
                  <Text className="text-header">
                    {product.status ? product.status.canceled : '-'} 点
                  </Text>
                </Col>
              </Row>
            </div>
          </Spin>
        </Col>
      </Row>
      {hasRental && (
        <div
          className="mt-1 text-right"
          style={{ backgroundColor: `#f5f5f5`, padding: 8 }}
        >
          <LinkButton
            to={`/accounts/${accountId}/rental-products/${item.pNo}/transfer`}
            type="default"
            app
          >
            <Icon type="double-right" />
            <span>店舗移動</span>
          </LinkButton>
          <Divider type="vertical" />
          <LinkButton
            to={`/accounts/${accountId}/rental-products/${item.pNo}/exchange`}
            type="default"
            app
          >
            <Icon type="swap" />
            <span>交換</span>
          </LinkButton>
          <Divider type="vertical" />
          <LinkButton
            to={`/accounts/${accountId}/rental-products/${item.pNo}/cancel`}
            type="default"
            app
          >
            <Icon type="stop" />
            <span>解約</span>
          </LinkButton>
        </div>
      )}
    </>
  )
}
