import { fileClient } from '../http'
import { RESPONSE_OK } from '../constants'
import download from '../download'

export default {
  async csv(params) {
    const url = `/api/proceeds/download`
    const response = await fileClient.get(url, { params })

    if (response.status === RESPONSE_OK) {
      const fileName = `proceeds_${params.term}.csv`
      download(response.data, fileName)
    }
  },
}
