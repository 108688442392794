import React, { useState } from 'react'
import { Form, Input, Select, Modal, Spin } from 'antd'

// 社員情報変更モーダル
const StaffInfoChangeModal = ({ onOk, onCancel, selectedStaffItem, stores }) => {
  const [loading, setLoading] = useState(false)
  const [staffName, setStaffName] = useState(selectedStaffItem.staffName)
  const [storeId, setStoreId] = useState(selectedStaffItem.storeId)

  const staffNameInputError = () => {
    return !staffName.length ? '入力が必須です' :
           staffName.length > 50 ? '50文字以内で入力して下さい' : ''
  }
  const inputErrors = {
    staffName: staffNameInputError(),
  }
  const existsStaffInputError = Object.values(inputErrors).filter(item => item !== '').length

  const disabled =
    selectedStaffItem.staffName === staffName && selectedStaffItem.storeId === storeId ||
    existsStaffInputError

  return (
    <Modal
      title="社員情報の変更"
      visible={!!selectedStaffItem}
      okText="変更する"
      okType="primary"
      okButtonProps={{ disabled: disabled}}
      cancelText="戻る"
      onOk={() => onOk(selectedStaffItem.staffNo, staffName, storeId, setLoading)}
      onCancel={onCancel}
    >
      <Spin spinning={loading}>
        <Form layout="vertical" colon={false} labelAlign="left">
          <p className="mb-2">社員番号：{selectedStaffItem.staffNo}</p>
          <Form.Item label="氏名" style={{ marginBottom: 0, paddingBottom: 0 }}>
            <span className='text-sm text-red'>{inputErrors.staffName}</span>
            <div className="mb-2">
              <Input style={{ width: '100%' }} value={staffName} onChange={(event) => setStaffName(event.target.value)}/>
            </div>
          </Form.Item>
          <Form.Item label="所属支店" style={{ marginBottom: 0, paddingBottom: 0 }} className="mb-2">
            <div className="mb-2">
              <Select showSearch optionFilterProp="children" value={storeId} style={{ width: '100%' }} onChange={(val) => setStoreId(val)}>
                {stores && stores.map(item => <Select.Option key={item.storeId} value={item.storeId}>{item.storeName}</Select.Option>)}
              </Select>
            </div>
          </Form.Item>
        </Form>
        <p className="mt-2 text-red">※変更すると対象社員番号すべての情報が更新されます</p>
        <p className="mb-2 text-red">※所属支店を変更すると、現状のアカウントでは非表示になり異動先の支店での表示に変わります</p>
        <p>対象社員の情報を変更しますか？</p>
      </Spin>
    </Modal>
  )
}

export default StaffInfoChangeModal