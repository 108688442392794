import httpClient from '../http'

export function exchangeReasons() {
  return httpClient.get('/api/rentals/exchange-reasons')
}

export function usageDays(params) {
  return httpClient.get('/api/rentals/usage-days', { params })
}

export function cancel(data) {
  return httpClient.post('/api/rentals/cancel', data)
}

export function exchangeProducts(data) {
  return httpClient.post(`/api/rentals/exchange/products`, data )
}

export function exchangeDeliveryFee(data) {
  return httpClient.post('/api/rentals/exchange/delivery-fee', data)
}

export function exchange(data) {
  return httpClient.post('/api/rentals/exchange', data)
}

export function transfer(data) {
  return httpClient.post('/api/rentals/transfer', data)
}

export default {
  dropExchange(data) {
    return httpClient.delete('/api/rentals/exchange', {data})
  },

  dropCancel(data) {
    return httpClient.delete('/api/rentals/cancel', {data})
  }
}
