import { useState } from 'react'
import scrollTop from '../scroll-top'

export default function useStep(initialNum = 0) {
  const [currentStep, setCurrentStep] = useState(initialNum)

  const incrementStep = () => {
    scrollTop()
    setCurrentStep(currentStep + 1)
  }
  const decrementStep = () => {
    scrollTop()
    setCurrentStep(currentStep - 1)
  }

  const isStep = val => currentStep === val

  return { currentStep, incrementStep, decrementStep, isStep }
}
