import React from 'react'
import Tag from './Tag'
import {
  FORUM_STATUS_TODO,
  FORUM_STATUS_IN_PROGRESS,
  FORUM_STATUS_COMPLETED
} from '../constants'

function getType(status) {
  switch (status) {
    case FORUM_STATUS_TODO:
      return 'danger'
    case FORUM_STATUS_IN_PROGRESS:
      return 'gold'
    case FORUM_STATUS_COMPLETED:
      return 'primary'
    default:
      return ''
  }
}

function ForumTag({ status, children }) {
  return <Tag type={getType(status)}>{children}</Tag>
}

export default ForumTag
