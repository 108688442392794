import React from 'react'
import { Modal } from 'antd'

// 社員情報検証モーダル
export default function StaffValidateModal({ inputStaffInfo, currentStaffInfo, setCurrentStaffInfo, onOk }) {
  return (
    <Modal
      title="社員情報の確認"
      visible={currentStaffInfo.isConfirm}
      okText="OK"
      okType="danger"
      cancelText="戻る"
      onOk={() => { setCurrentStaffInfo({isConfirm: false, staffName: '', storeName: ''}); onOk() }}
      onCancel={() => setCurrentStaffInfo({isConfirm: false, staffName: '', storeName: ''})}
    >
      <p className="mb-2">
        入力された社員番号は、既に登録されている情報があります。入力された社員情報の注文として続けてもよろしいですか？<br/><br/>
        <b>社員番号：</b>{inputStaffInfo.staffNo}<br/>
        <b>社員氏名：</b>{currentStaffInfo.staffName}
          {currentStaffInfo.staffName !==inputStaffInfo.staffName && <span className='text-red'> → {inputStaffInfo.staffName}</span>}<br/>
        <b>所属支店：</b>{currentStaffInfo.storeName}
          {currentStaffInfo.storeName !==inputStaffInfo.storeName && <span className='text-red'> → {inputStaffInfo.storeName}</span>}
      </p>
    </Modal>
  )
}
