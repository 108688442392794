import React from 'react'
import Page from '../components/Page'
import ProductShowBody from '../components/ProductShowBody'
import useShippingMessage from '../hooks/shipping-message'

function ProductShow({ id }) {
  const shippingMessage = useShippingMessage()

  return (
    <Page title="商品詳細">
      <ProductShowBody
        id={id}
        backLink="/products"
        shippingMessage={shippingMessage}
      />
    </Page>
  )
}

export default ProductShow
