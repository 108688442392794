import React from 'react'
import { Row, Col, Affix } from 'antd'
import Page from '../components/Page'
import ProductSearchBody from '../components/ProductSearchBody'
import SaleFromToBox from '../components/SaleFromToBox'
import useSalesFromTo from '../hooks/sale-from-to'

function AccountProductSearch({ id }) {
  const [from, to] = useSalesFromTo(id)
  const renderDetailLink = productId => `/accounts/${id}/products/${productId}`

  return (
    <Page title="取り扱い商品">
      <Row gutter={32} type="flex">
        <Col span={6}>
          {from && to && (
            <Affix offsetTop={16}>
              <SaleFromToBox from={from} to={to} />
            </Affix>
          )}
        </Col>
        <Col span={18}>
          <ProductSearchBody
            accountId={id}
            renderDetailLink={renderDetailLink}
          />
        </Col>
      </Row>
    </Page>
  )
}

export default AccountProductSearch
