import React from 'react'
import { Icon, Divider } from 'antd'
import { RETURN_STATUS_OK } from '../constants'
import Pill from './Pill'

function ReturnTags({ status, orderType, checkedDate }) {
  if (orderType <= 2) {
    return null
  }

  const iconName = status === RETURN_STATUS_OK
    ? 'check-circle'
    : 'exclamation-circle'

  const type = status === RETURN_STATUS_OK ? 'success' : 'error'

  return (
    <>
      <Divider type="vertical" />
      <Pill icon={<Icon type={iconName} theme="filled" />} type={type}>
        <span className="ml-1">{checkedDate ? "返品完了" : "返品待ち"}</span>
        <Divider type="vertical" />
        {checkedDate ? (
          <span>{checkedDate}</span>
        ) : (
          <span className="text-red">
            {checkedDate ? checkedDate : "商品の返却はお済みですか？"}
          </span>
        )}
      </Pill>
    </>
  )
}

export default ReturnTags
