import React from 'react'
import { Modal } from 'antd'

// エラー表示モーダル
export default function ErrorShowModal({ title, visible, setVisible, errorMessages, setErrorMessages }) {
  return (
    <Modal
      title={title}
      visible={visible}
      okText="OK"
      cancelText="戻る"
      okType="ghost"
      onOk={() => { setVisible(false); setErrorMessages([]) }}
      onCancel={() => { setVisible(false); setErrorMessages([]) }}
      width={700}
    >
      <div>
        {errorMessages.map(elm => <p>{elm}</p>)}
      </div>
    </Modal>
  )
}
