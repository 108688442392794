import { useEffect, useState } from 'react'
import returnApi from '../../../api/return-accept'

export default function useFetchReturnInfo({ id, setLoading, isAdmin}) {
  const [returnInfo, setReturnInfo] = useState(null)

  const refreshReturnInfo = async (id) => {
    const result = await returnApi.getReturnInfo(id)

    if (result) {
      setReturnInfo(result.data)
    }
  }

  useEffect(() => {
    if (!id) return () => {}
    (async () => {
      setLoading(true)
      if(isAdmin) {
        await refreshReturnInfo(id)
      }
      setLoading(false)
    })()
  }, [id])

  return { returnInfo, refreshReturnInfo }
}
