import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  response: null,
}

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setResponse(state, action) {
      state.response = action.payload
    },
  },
})

export default slice.reducer

export const { setResponse } = slice.actions

export function useResponseErrorStatus() {
  return useSelector(state => {
    return state.error.response ? state.error.response.status : null
  })
}
