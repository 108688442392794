import React from 'react'
import { Affix, Col, Row } from 'antd'
import Page from '../components/Page'
import useSalesFromTo from '../hooks/sale-from-to'
import SaleFromToBox from '../components/SaleFromToBox'
import ProductShowBody from '../components/ProductShowBody'

function AccountProductShow({ id, productId }) {
  const [from, to] = useSalesFromTo(id)

  return (
    <Page title="取り扱い商品">
      <Row gutter={32} type="flex">
        <Col span={6}>
          {from && to && (
            <Affix offsetTop={16}>
              <SaleFromToBox from={from} to={to} />
            </Affix>
          )}
        </Col>
        <Col span={18}>
          <ProductShowBody
            id={productId}
            backLink={`/accounts/${id}/products`}
            canOrder={false}
            accountId={id}
          />
        </Col>
      </Row>
    </Page>
  )
}

export default AccountProductShow
