import httpClient from '../http'

export default {
  index() {
    return httpClient.get(`/api/carts`)
  },

  add(data) {
    return httpClient.post(`/api/carts`, data)
  },

  update(data) {
    return httpClient.patch(`/api/carts/order-counts`, data)
  },

  submit(data) {
    return httpClient.post(`/api/orders`, data)
  },

  delete(id, data) {
    return httpClient.put(`/api/carts/${id}/delete`, data)
  },

  deliveryFee(data) {
    return httpClient.post(`/api/carts/delivery-fee`, data)
  },

  shippingAddresses() {
    return httpClient.get(`/api/shipping-addresses`)
  },

  deliveryTimes() {
    return httpClient.get(`/api/delivery-times`)
  },
}
