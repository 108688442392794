import moment from 'moment'
import { DATE_FORMAT, DATE_FORMAT_DETAIL, MONTH_FORMAT } from './constants'

const numFormatter = new Intl.NumberFormat('ja-JP')

/**
 * 数値のフォーマット
 * @param {number|string} number
 * @returns {string}
 */
export function numFormat(number) {
  return numFormatter.format(number * 1)
}

/**
 * 日付のフォーマット
 * @param {string} date
 * @returns {string}
 */
export function dateFormat(date) {
  return moment(date).format(DATE_FORMAT)
}

/**
 * 日付&時刻のフォーマット
 * @param {string} date
 * @returns {string}
 */
export function dateDetailFormat(date) {
  return moment(date).format(DATE_FORMAT_DETAIL)
}

/**
 * 月のフォーマット
 * @param {string} date
 * @returns {string}
 */
export function monthFormat(date) {
  return moment(date).format(MONTH_FORMAT)
}

/** 全角文字を半角文字に変換 */
export function toHalfWidth(str){
    return str.replace(
        /[！-～]/g,
        (char) => String.fromCharCode(char.charCodeAt(0) - 0xFEE0)
    );
}
