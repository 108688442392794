import React,{ useEffect, useState } from 'react'
import { Form, Input, Button, Row, Col, Spin, Result } from 'antd'
import { RESPONSE_OK } from '../constants'
import accountsApi from '../api/accounts'
import { invalidPasswordReason } from '../validator'
import { Link } from '@reach/router'

const PASSWORD_CHANGE = 'パスワード変更'
const PASSWORD_CHANGE_COMPLETE = 'パスワード変更完了'

const initialData = {
  password: '',
  reconfirmPassword : '',
  mailAddress: ''
}

const toPostData = (password, token, email) => ({
  token: token,
  password: password,
  mailAddress: email
})

function PasswordChange() {
  const [scene, setScene] = useState(PASSWORD_CHANGE)
  const isCurrentScene = (element) => element === scene
  const [loading, setLoading] = useState(false)
  const [isValidToken, setIsValidToken] = useState(true)
  const [token, setToken] = useState('');
  const [mailAddress, setMailAddress] = useState('')
  const [companyOmit, setCompanyOmit] = useState('')

  useEffect(() => {
    (async () => {
      setLoading(true)
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      const response = await accountsApi.passwordChangeCheckToken({token: params['token']})
      if (response.status === RESPONSE_OK) {
        setToken(params['token'])
        setIsValidToken(true)
        setMailAddress(response.data)
      } else {
        setIsValidToken(false)
      }
      setLoading(false)
    })()
  }, [])

  return (
    <div>
      {isValidToken && isCurrentScene(PASSWORD_CHANGE) &&
        <PasswordChangeScene
          token={token}
          email={mailAddress}
          loading={loading}
          setLoading={setLoading}
          setScene={setScene}
          setCompanyOmit={setCompanyOmit}
          setIsValidToken={setIsValidToken}
        />
      }
      {isValidToken && isCurrentScene(PASSWORD_CHANGE_COMPLETE) &&
        <PasswordChangeCompleteScene
          companyOmit={companyOmit}
        />
      }
      {(!isValidToken && !loading) &&
        <InvalidAccessTokenMessage/>
      }
    </div>
  )
}

function PasswordChangeScene({ token, email, loading, setLoading, setScene, setCompanyOmit, setIsValidToken }) {
  const [password, setPassword] = useState('')
  const [reconfirmPassword, setReconfirmPassword] = useState('')

  const invalidPassword = () => invalidPasswordReason(password).length > 0
  const invalidPasswordMessage = () =>
    password.length > 0 ?
      (invalidPassword() ? invalidPasswordReason(password) : '')
    : ''
  const invalidReconfirmPassword = () => password !== reconfirmPassword
  const invalidReconfirmPasswordMessage = () =>
    reconfirmPassword.length > 0 ?
      (invalidReconfirmPassword() ? '同じパスワードを入力する必要があります' : '')
    : ''

  const handleSubmit = async () => {
    setLoading(true)
    const response = await accountsApi.passwordChangeResetToken(toPostData(password, token, email))
    if (response.status === RESPONSE_OK) {
      setScene(PASSWORD_CHANGE_COMPLETE)
      setCompanyOmit(response.data)
    } else {
      setIsValidToken(false)
    }
    setLoading(false)
  }

  const handleInput = data => {
    const patch = {}
    const initialKeys = Object.keys(initialData)
    Object.keys(data).forEach(key => {
      if (initialKeys.includes(key)) {
        patch[key] = data[key]
      }
    })
    if (data['password'] !== undefined) {
      setPassword(data['password'])
    }
    if (data['reconfirmPassword'] !== undefined) {
      setReconfirmPassword(data['reconfirmPassword'])
    }
  }

  return (
    <div className='password-change-mail-input'>
      <div className="main">
        <Spin spinning={loading}>
          <Row type="flex" justify="center">
            <Col span={18}>
              <h1 className="text-center text-bold text-md">パスワード変更</h1>
            </Col>
            <Col className="mb-3" style={{ padding: 8 }}>
              対象のメールアドレスのパスワードを入力して下さい
            </Col>
          </Row>
          <Form layout="vertical">
            <Form.Item
              label="メールアドレス"
            >
              <div>{email}</div>
            </Form.Item>
            <Form.Item
              label="パスワード"
              help={invalidPasswordMessage()}
              validateStatus={invalidPasswordMessage().length > 0 ? 'error' : ''}
            >
              <Input.Password
                value={password}
                placeholder="新しいパスワードを入力してください"
                onChange={e => handleInput({ password: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="パスワード（確認用）"
              help={invalidReconfirmPasswordMessage()}
              validateStatus={(invalidReconfirmPasswordMessage().length > 0) ? 'error' : ''}
            >
              <Input.Password
                value={reconfirmPassword}
                placeholder="確認のため同じパスワードを入力してください"
                onChange={e => handleInput({ reconfirmPassword: e.target.value })}
              />
            </Form.Item>
            <div className="text-center mt-2">
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={invalidPassword() || invalidReconfirmPassword()}
            >
              変更する
            </Button>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  )
}

function PasswordChangeCompleteScene({companyOmit}) {
  return (
    <div className='password-change-mail-input'>
      <div className="main">
        <Row type="flex" justify="center">
          <Col>
            <h1 className="text-center text-bold text-md">パスワードを変更しました</h1>
          </Col>
          <Col className="text-sm" style={{ paddingTop: 16 }}>
            <Link to={`/${companyOmit}/login`}>ログイン</Link>画面より変更後のパスワードでログイン出来ます。
          </Col>
        </Row>
      </div>
    </div>
  )
}

function InvalidAccessTokenMessage() {
  return (
    <Result
      status="warning"
      title="パスワード変更の許可情報が無効です"
    >
      <div className="text-center text-paragraph">
        有効な認証情報が無いため、コンテンツを表示できませんでした。<br/>
        <Link to="/login">ログイン画面</Link>より、パスワード変更メールを発行して下さい。
      </div>
    </Result>
  )
}

export default PasswordChange