import React from 'react'
import { Link, navigate } from '@reach/router'
import store from '../store'
import { companyCodeSelector, useCompanyCode } from '../hooks/auth'

export default function AppLink({ to, children, ...props }) {
  const companyCode = useCompanyCode()

  return <Link to={`/${companyCode}${to}`} {...props}>{children}</Link>
}

export function appNavigate(to, state) {
  const companyCode = companyCodeSelector(store.getState())
  navigate(`/${companyCode}${to}`, { state: state } )
}
