import React, { useMemo } from 'react'
import moment from 'moment'
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Table
} from 'antd'
import AppLink from '../../../components/AppLink'
import { DATE_FORMAT } from '../../../constants'

const { Column } = Table

export default function ReturnAcceptInput({
  products,
  counts,
  comment,
  receivedAt,
  onCountChange,
  onCommentChange,
  onReceivedAtChange,
  onConfirm
}) {
  const canConfirm = useMemo(() => {
    if (!counts) return false

    const allCountsAreMatched = products.every(({ pNo, orderCount }) => (
      orderCount === counts[pNo].ok + counts[pNo].repair + counts[pNo].discard
    ))

    const someOk = products.some(({ pNo }) => counts[pNo].ok > 0)

    const receivedAtIsFilled = someOk ? receivedAt : true

    return allCountsAreMatched && receivedAtIsFilled
  }, [products, counts, receivedAt])

  return (
    <Form
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      labelAlign="left"
      colon={false}
    >
      <p className="text-paragraph text-header mb-3">
        返送された商品に間違いがないか、数量を反映してください。<br />
        間違いがある場合、<AppLink to="/forums">お問い合わせ</AppLink>よりお客様に確認を行ってください。
      </p>

      <div className="mb-4">
        <Table
          bordered
          pagination={false}
          dataSource={products}
          rowKey="pNo"
        >
          <Column
            key="product"
            title="商品"
            render={(_, record) => (
              <div className="text-paragraph">
                <div>
                  <AppLink to={`/products/${record.productId}`}>
                    {record.productTitle}
                  </AppLink>
                  {record.title && (
                    <>
                      <Divider type="vertical" />
                      {record.title}
                    </>
                  )}
                </div>
                <div>
                  {record.contentTitle && (
                    <>
                      {record.contentTitle}
                      <Divider type="vertical" />
                    </>
                  )}
                  {record.pNo}
                  <Divider type="vertical" />
                  {record.size}
                </div>
              </div>
            )}
          />
          <Column
            key="orderCount"
            title="申込数"
            dataIndex="orderCount"
            className="min-cell"
          />
          <Column
            key="ok"
            title="良品数"
            className="min-cell success-cell"
            render={(_, { pNo, orderCount }) => counts ? (
              <InputNumber
                min={0}
                max={orderCount - counts[pNo].repair - counts[pNo].discard}
                value={counts[pNo].ok}
                onChange={val => onCountChange(pNo, { ok: val })}
              />
            ) : null}
          />
          <Column
            key="repair"
            title="修繕数"
            className="min-cell warning-cell"
            render={(_, { pNo, orderCount }) => counts ? (
              <InputNumber
                min={0}
                max={orderCount - counts[pNo].ok - counts[pNo].discard}
                value={counts[pNo].repair}
                onChange={val => onCountChange(pNo, { repair: val })}
              />
            ) : null}
          />
          <Column
            key="discard"
            title="破棄数"
            className="min-cell error-cell"
            render={(_, { pNo, orderCount }) => counts ? (
              <InputNumber
                min={0}
                max={orderCount - counts[pNo].ok - counts[pNo].repair}
                value={counts[pNo].discard}
                onChange={val => onCountChange(pNo, { discard: val })}
              />
            ) : null}
          />
        </Table>
      </div>

      <Form.Item label="入庫予定日（良品のみ）">
        <DatePicker
          value={receivedAt ? moment(receivedAt) : null}
          onChange={date => onReceivedAtChange(
            date ? date.format('YYYY-MM-DD') : null
          )}
          format={DATE_FORMAT}
        />
      </Form.Item>

      <Form.Item label="備考">
        <Input.TextArea
          value={comment}
          onChange={e => onCommentChange(e.target.value)}
        />
      </Form.Item>

      <Divider />

      <div className="text-right">
        <Button type="primary" onClick={onConfirm} disabled={!canConfirm}>
          確認する
        </Button>
      </div>
    </Form>
  )
}
