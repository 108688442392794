import React from 'react'
import { Link } from '@reach/router'
import AppLink from './AppLink'

function LinkButton({ to, state, children, ghost, small, type = 'link', app }) {
  const classNames = [`ant-btn`, `ant-btn-${type}`]

  if (ghost) {
    classNames.push(`ant-btn-background-ghost`)
  }

  if (small) {
    classNames.push(`ant-btn-sm`)
  }

  const props = {
    to,
    state,
    children,
    className: classNames.join(' ')
  }

  return app ? <AppLink {...props} /> : <Link {...props} />
}

export default LinkButton
