import { useEffect, useState } from 'react'
import { products as rentalStatusProductsApi } from '../../../api/rental-status'
import productApi from '../../../api/product'
import { summary as rentalStatusSummaryApi } from '../../../api/rental-status'
import { RESPONSE_OK } from '../../../constants'

const initialQuery = { productName: '', page: 1 }

export default function useSearchProducts(accountId) {
  const [query, setQuery] = useState(initialQuery)
  const [pageInfo, setPageInfo] = useState(null)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      setLoading(true)
      const response = await rentalStatusProductsApi(accountId, query)
      if (response.status === RESPONSE_OK) {
        const { results, ...rest } = response.data
        setProducts(results)
        setPageInfo(rest)
      }
      setLoading(false)
    })()
  }, [accountId, query])

  const search = params => setQuery(Object.assign({}, query, params))

  const fetchSetContents = async product => {
    const response = await productApi.setContents(product.pNo)

    const newProducts = products.map(item => {
      if (item.pNo !== product.pNo) return item
      return Object.assign({}, item, { setContents: response.data })
    })

    setProducts(newProducts)
  }

  const fetchStatus = async product => {
    const response = await rentalStatusSummaryApi(product.pNo)

    const newProducts = products.map(item => {
      if (item.pNo !== product.pNo) return item
      return Object.assign({}, item, { status: response.data })
    })

    setProducts(newProducts)
  }

  return { search, pageInfo, products, fetchSetContents, fetchStatus, loading }
}
